import { DateTime } from 'luxon'
import { percentageDiff, weightDiff } from '../../utils/utils'

export const submissionsBeforePoint = (submissionsNew, point, timestamp) => {
  return submissionsNew.filter((sub) => {
    const startOfTheDay = DateTime.fromISO(sub.date, { zone: 'Europe/Riga' }).startOf(
    'day',
    ).toSeconds()
    return startOfTheDay < timestamp && sub.point === point
  })
}

export const submissionsBefore = (submissionsNew, timestamp) => {
  // return submissionsNew.filter((sub) => sub.createdAt < timestamp)
  return submissionsNew.filter((sub) => {
    const startOfTheDay = DateTime.fromISO(sub.date, { zone: 'Europe/Riga' }).startOf(
    'day',
    ).toSeconds()
    return startOfTheDay < timestamp
  })
}

export const submissionsAfterPoint = (submissionsNew, point, timestamp) => {
  return submissionsNew.filter((sub) => {
    const startOfTheDay = DateTime.fromISO(sub.date, { zone: 'Europe/Riga' }).startOf(
    'day',
    ).toSeconds()
    return startOfTheDay <= timestamp && sub.point === point
  })
}

export const submissionsBeforeDriver = (submissionsNew, timestamp) => {
  return submissionsNew.filter((sub) => {
    const startOfTheDay = DateTime.fromISO(sub.date, { zone: 'Europe/Riga' }).startOf(
    'day',
    ).toSeconds()
    return startOfTheDay < timestamp
  })
}

export const submissionsAfterDriver = (submissionsNew, timestamp) => {
  return submissionsNew.filter((sub) => {
    const startOfTheDay = DateTime.fromISO(sub.date, { zone: 'Europe/Riga' }).startOf(
    'day',
    ).toSeconds()
    return startOfTheDay <= timestamp
  })
}

export const transfersBefore = (transfers, timestamp) => {
  return transfers.filter((transfer) => {
    const startOfTheDay = DateTime.fromISO(transfer.date, { zone: 'Europe/Riga' }).startOf(
    'day',
    ).toSeconds()
    return startOfTheDay < timestamp
  })
}

export const transfersAfter = (transfers, timestamp) => {
  return transfers.filter((transfer) => {
    const startOfTheDay = DateTime.fromISO(transfer.date, { zone: 'Europe/Riga' }).startOf(
    'day',
    ).toSeconds()
    return startOfTheDay <= timestamp
  })
}

export const boxesBefore = (submissionsNew, point, timestamp) => {
  const subsBefore = submissionsBeforePoint(submissionsNew, point, timestamp)
  const takenProducts = subsBefore.map((sub) => sub.products).flat()
  const takenBoxes = takenProducts.reduce(
    (takenBoxes, item) => takenBoxes + item.boxesTaken,
    0,
  )
  const givenBoxes = subsBefore.reduce(
    (givenBoxes, sub) => givenBoxes + sub.boxesLeft,
    0,
  )
  return givenBoxes - takenBoxes
}

export const boxesAfter = (submissions, point, timestamp) => {
  const subsBefore = submissionsAfterPoint(submissions, point, timestamp)
  subsBefore.forEach((element) => {})
  const takenProducts = subsBefore.map((sub) => sub.products).flat()
  const takenBoxes = takenProducts.reduce(
    (takenBoxes, item) => takenBoxes + item.boxesTaken,
    0,
  )
  const givenBoxes = subsBefore.reduce(
    (givenBoxes, sub) => givenBoxes + sub.boxesLeft,
    0,
  )
  return givenBoxes - takenBoxes
}

export const pointFundsBefore = (submissionsNew, point, timestamp) => {
  const subsBefore = submissionsBeforePoint(submissionsNew, point, timestamp)

  const totalPaidOut = subsBefore.reduce(
    (prev, current) => prev + current.paidOutFunds,
    0,
  )

  const totalSumToBePaid = subsBefore.reduce(
    (prev, current) => prev + current.sumToBePaid(),
    0,
  )

  const totalReturnedFunds = subsBefore.reduce(
    (prev, current) => prev + current.returnedFunds,
    0,
  )

  const paidOutFunds = subsBefore.reduce(
    (paidOutFunds, sub) => totalPaidOut - totalSumToBePaid - totalReturnedFunds,
    0,
  )
  return Math.round((paidOutFunds + Number.EPSILON) * 100) / 100
}

export const allPointFundsBefore = (submissions, timestamp) => {
  const subsBefore = submissionsBefore(submissions, timestamp)

  const totalPaidOut = subsBefore.reduce(
    (prev, current) => prev + current.paidOutFunds,
    0,
  )

  const totalSumToBePaid = subsBefore.reduce(
    (prev, current) => prev + current.sumToBePaid(),
    0,
  )

  const totalReturnedFunds = subsBefore.reduce(
    (prev, current) => prev + current.returnedFunds,
    0,
  )

  const paidOutFunds = subsBefore.reduce(
    (paidOutFunds, sub) => totalPaidOut - totalSumToBePaid - totalReturnedFunds,
    0,
  )
  return Math.round((paidOutFunds + Number.EPSILON) * 100) / 100
}

export const pointFundsAfter = (submissionsNew, point, timestamp) => {
  const subsAfter = submissionsAfterPoint(submissionsNew, point, timestamp)

  const totalPaidOut = subsAfter.reduce(
    (prev, current) => prev + current.paidOutFunds,
    0,
  )

  const totalSumToBePaid = subsAfter.reduce(
    (prev, current) => prev + current.sumToBePaid(),
    0,
  )

  const totalReturnedFunds = subsAfter.reduce(
    (prev, current) => prev + current.returnedFunds,
    0,
  )

  // const takenProducts = subsBefore.map((sub) => sub.products).flat()
  // const takenBoxes = takenProducts.reduce(
  //   (takenBoxes, item) => takenBoxes + item.boxesTaken,
  //   0,
  // )
  const paidOutFunds = subsAfter.reduce(
    (paidOutFunds, sub) =>
      // sub.sumToBePaid() - sub.paidOutFunds - sub.returnedFunds,
      // sub.paidOutFunds - sub.sumToBePaid() - sub.returnedFunds,
      totalPaidOut - totalSumToBePaid - totalReturnedFunds,
    0,
  )

  return Math.round((paidOutFunds + Number.EPSILON) * 100) / 100
}

export const totalProductsSum = (submissions) => {
  const productsAll = submissions.map((sub) => sub.products).flat()
  const reducerSum = (prev, current) => prev + current.sum

  return productsAll.reduce(reducerSum, 0)
}

export const driverFunds = (submissions, transfers, timestamp, type) => {
  // console.log('run54')
  let subs = null
  let driverTransfersReceived = null
  let driverTransfersSent = null
  if (type === 'before') {
    subs = submissionsBeforeDriver(submissions, timestamp)
    const getTransfers = transfersBefore(transfers, timestamp)
    driverTransfersReceived = getTransfers.filter(
      (transfer) => transfer.type === 'send',
    )

    driverTransfersSent = getTransfers.filter(
      (transfer) => transfer.type === 'take',
    )
  } else if (type === 'after') {
    subs = submissionsAfterDriver(submissions, timestamp)
    const getTransfers = transfersAfter(transfers, timestamp)
    driverTransfersReceived = getTransfers.filter(
      (transfer) => transfer.type === 'send',
    )

    driverTransfersSent = getTransfers.filter(
      (transfer) => transfer.type === 'take',
    )
  }

  const reducerTransferred = (prev, current) => prev + current.amount
  const totalTransfersReceived = driverTransfersReceived.reduce(
    reducerTransferred,
    0,
  )
  const totalTransfersSent = driverTransfersSent.reduce(reducerTransferred, 0)

  const productSum = totalProductsSum(subs)

  const totalPaidSalary = subs.reduce(
    (prev, current) => prev + current.paidSalary,
    0,
  )

  console.log('funds1', totalTransfersReceived, totalTransfersSent, productSum, totalPaidSalary)

  let fundsAtDriverNow =
    totalTransfersReceived - totalTransfersSent - productSum - totalPaidSalary

  return Math.round((fundsAtDriverNow + Number.EPSILON) * 100) / 100
}

export const dailyReceivedFunds = (transfers) => {
  if (!transfers) return 0
  const received = transfers.filter((item) => item.type === 'send')
  const reducer = (prev, next) => prev + next.amount
  const sum = received.reduce(reducer, 0)
  return <span style={{ color: 'green' }}>+{sum} EUR</span>
}

export const dailyGivenBackdFunds = (transfers) => {
  if (!transfers) return 0
  const received = transfers.filter((item) => item.type === 'take')
  const reducer = (prev, next) => prev + next.amount
  const sum = received.reduce(reducer, 0)
  return <span style={{ color: 'red' }}>-{sum} EUR</span>
}

export const dailyTransfers = (transfers, date) => {
  return transfers.filter((item) => item.date === date)
}

export const dailyPurchaseWeighting = (weightings, date) => {
  return weightings?.find((item) => item.date === date)
}

export const dailySubmissions = (submissions, date) => {
  return submissions.filter((item) => item.date === date)
}

export const submissionProducts = (submissions) => {
  if (!submissions) return null
  let products = []

  submissions.forEach((item) => {
    products = [...products, item.products]
  })
  return products.flat()
}

export const dailyProductsStatistics = (submissions) => {
  const products = submissionProducts(submissions)
  let productsStats = []
  products.forEach((product) => {
    if (!productsStats.some((stat) => stat.type === product.type)) {
      productsStats.push({
        type: product.type,
        quantity: product.quantity,
        quantityDriver: product.quantityDriver,
        sum: product.sum,
      })
    } else {
      const currentProductStat = productsStats.findIndex(
        (stat) => stat.type === product.type,
      )
      console.log(productsStats[currentProductStat])
      productsStats[currentProductStat].quantity += product.quantity
      productsStats[currentProductStat].quantityDriver += product.quantityDriver
      productsStats[currentProductStat].sum += product.sum
    }
  })
  return productsStats
}

export const productsSum = (products) => {
  return products.flat().reduce((prev, next) => prev + next.sum, 0)
}

export const productsSumByType = (submissions, type) => {
  if (!submissions) return null
  return submissionProducts(submissions)
    .filter((sub) => sub.type === type)
    .reduce((prev, next) => {
      return prev + next.sum
    }, 0)
}

export const pointSalaries = (submissions) => {
  return submissions.reduce((prev, next) => prev + next.paidSalary, 0)
}
