import React, { Component } from 'react'
import { Statistic, Row, Col } from 'antd'
import { roundNumber } from '../../utils/utils'
import { allPointFundsBefore } from '../db/dbQueries'
import useStore from './../../state/store'

export const ProductWeight = (props) => {
  const subs = useStore((state) => state.submissions)
  if (!props?.products) return
  const { products, boxesTaken, weightSum, totalSum, fundsAtDriverNow } = props

  const listItems = products.map((product, i) => {
    return (
      <>
        <Row>
          <strong>{product?.name}</strong>: {product?.weight} kg |{' '}
          {product?.sum} EUR
        </Row>
      </>
    )
  })

  return (
    <>
      {listItems}
      <strong>Kopā</strong>: {weightSum} kg | {totalSum} EUR
      <br />
      <strong>Kopā paņemtas</strong>: {boxesTaken} kastes
      <hr />
      <strong>Nauda punktos: </strong> {allPointFundsBefore(subs, Date.now())}{' '}
      EUR
      <br />
      <strong>Nauda pie šofera: </strong> {fundsAtDriverNow - allPointFundsBefore(subs, Date.now())} EUR
      <br />
      <strong>Šofera kase kopā: </strong>{' '}
      {roundNumber(fundsAtDriverNow)}{' '}
      EUR
    </>
  )
}
