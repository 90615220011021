import create from 'zustand'
import { persist } from 'zustand/middleware'
import { get, set, del } from 'idb-keyval' // can use anything: IndexedDB, Ionic Storage, etc.
// Custom storage object
const storage = {
  getItem: async (name) => {
    return (await get(name)) || null
  },
  setItem: async (name, value) => {
    await set(name, value)
  },
  removeItem: async (name) => {
    await del(name)
  },
}

export const useStore = create(
  persist(
    (set) => ({
      loading: true,
      //   setLoading: () => set((loading) => ({ loading: loading })),
      //   avatar_url:
      // 'https://www.shareicon.net/data/128x128/2017/01/06/868320_people_512x512.png',
      //   setAvatarUrl: () => set((avatar) => ({ avatar_url: avatar })),
      //   username: null,
      //   setUsername: () => set((username) => ({ username: username })),
      loggedIn: null,
      setLoggedIn: (status) =>
        set((state) => ({
          loggedIn: true,
        })),
    }),
    {
      name: 'user-storage', // unique name
      getStorage: () => storage,
    },
  ),
)

// export default useStore
