import React, { useState, useEffect } from 'react'
import {
  Table,
  Button,
  Tag,
  Switch,
  Space,
  PageHeader,
  Alert,
  Descriptions,
  DatePicker,
  Form,
  Select,
  Typography,
  Cascader,
  Progress
} from 'antd'
import useStore from '../../state/store'
import { DailyStatsContainer } from './DailyStatsContainer'
import { PointStatisticsExtensive } from '../points/PointStatisticsExtensive'
import { FilterOutlined, DeleteOutlined } from '@ant-design/icons'
import {
  boxesBefore,
  boxesAfter,
  pointFundsBefore,
  pointFundsAfter,
} from '../db/dbQueries'
import { useQuery } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import { ExpandedRow } from './ExpandedRow'
import { Link, useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { useRxDB, useRxData, useRxDocument, useRxCollection } from 'rxdb-hooks';
import { validNumberFormatter } from '../../utils/utils'
import { DeleteConfirm } from "../ui/DeleteConfirm";
import { fetchSyncPurchases } from "../../utils/Api";
const { Option } = Select
const { Paragraph } = Typography

export const SubmissionOverview = (props) => {
  // TODO: Is activated on every comonent update. Could be possible to optimize further.

  const [submissions, setSubmissions] = useState({ submissions: {} })
  const now = DateTime.now()
  const date = now.toISODate()
  const [day, setDay] = useState(date)
  const [point, setPoint] = useState(null)
  const [viewType, setViewType] = useState(null)
  const [syncPercentage, setSyncPercentage] = useState(0)

console.log('subs123', submissions)

  const submissionCount = () => {
    if(!submissions || !Array.isArray(submissions)) {
      return 0
    } else {
      return submissions?.filter((item) => item.syncStatus === 'synced').length
    }
  }

  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)

  const subsCollection = useRxCollection('submissions');

  const { isLoading: isLoadingSync, error: errorSync, data: dataSync } = useQuery('purchaseCount', fetchSyncPurchases)

  console.log('dataSync', dataSync)

  useEffect(() => {
    const type = window.location.pathname
    if (type === '/points') {
      setViewType('points')
      setPoint(null)
    } else if (props.match.path === '/point/:id') {
      setViewType('point')
    } else if (type === '/daily') {
      setViewType('daily')
    } else {
      setViewType('submissions')
    }
  }, [window.location.pathname, props.match.path])

  useEffect(() => {
    if (viewType === 'point') {
      setPoint(parseInt(props.match.params.id))
    }
  }, [viewType])

  useEffect(() => {
    if (viewType === 'submissions') {
      if(dataSync && submissions) {
        setSyncPercentage(parseInt(submissionCount() * 100 / dataSync))
        console.log('222', submissionCount(), dataSync)
      }
    }
  }, [viewType, submissions])

  const products = useStore((state) => state.products)
  const points = useStore((state) => state.points)
  const subs = useStore((state) => state.submissions)
  const transfers = useStore((state) => state.transfers)
  const weightings = useStore((state) => state.weighting)
  const productStyle = useStore((state) => state.productStyle)

  const setCalendar = (date, dateString) => {
    if (!dateString) setDay(null)
    setDay(dateString)
  }

  const dailySubs = (submissions) => {
    if (!submissions || submissions?.length === 0) return null
    const filteredByDate = !day
      ? submissions
      : submissions.filter((item) => item.date === day)
    const sorted = filteredByDate.sort((a, b) => {
      return b.createdAt - a.createdAt
    })
    return sorted
  }

  const dailyTransfers = (transfers) => {
    const filteredByDate = !day
      ? transfers
      : transfers.filter((item) => item.date === day)
    const sorted = filteredByDate.sort((a, b) => {
      return b.createdAt - a.createdAt
    })
    return sorted
  }

  const filteredSubmission = () => {
    if (submissions[0]) {
      if (viewType === 'points') return submissions
      if (viewType === 'point')
        return submissions.filter((sub) => sub.point === point)
      if (viewType === 'submissions') return submissions
      if (viewType === 'daily') return dailySubs(submissions)
      // if (!day && !point) return submissions

      // if (day && point) {
      //   return submissions.filter(
      //     (item) =>
      //       item.date === String(day) &&
      //       item.purchase_point === parseFloat(point),
      //   )
      // } else if (day) {
      //   return submissions.filter((item) => item.date === String(day))
      // } else if (point) {
      //   return submissions.filter(
      //     (item) => item.purchase_point === parseFloat(point),
      //   )
      // } else {
      //   return
      // }
    }
  }

  // tempSub.purchase_point_name = p.purchase_point
  // tempSub.boxes_left = sub.boxes_left
  // tempSub.paid_out_funds = sub.paid_out_funds
  // tempSub.paid_salary = sub.paid_salary
  // tempSub.returned_funds = sub.returned_funds
  // tempSub.date = sub.date
  // tempSub.timestamp = sub.timestamp
  // tempSub.comment = sub.comment
  // tempSub.point_funds_before = sub.point_funds_before
  // tempSub.point_funds_after = sub.point_funds_after

  const cascaderFilterOptions = [
    {
      label: 'Kopā jāmaksā (€)',
      value: 'sumToBePaid',
    },
    {
      label: 'Izmaksātā nauda (€)',
      value: 'paid',
    },
    {
      label: 'Atgriestais avanss (€)',
      value: 'returnedFunds',
    },
    {
      label: 'Avanss (pirms)',
      value: 'point_funds_before',
    },
    {
      label: 'Avanss (pēc)',
      value: 'point_funds_after',
    },
    {
      label: 'Izmaksātā alga (€)',
      value: 'paidSalary',
    },
    {
      label: 'Kastes punktā sākumā',
      value: 'point_boxes_before',
    },
    {
      label: 'Kastes punktā beigās',
      value: 'point_boxes_after',
    },
    // {
    //   label: 'Kastes (šofera) sākumā',
    //   value: 'driver_boxes_before',
    // },
    // {
    //   label: 'Kastes (šofera) beigās',
    //   value: 'driver_boxes_after',
    // },
  ]

  let toBeNormalizedOptions = localStorage.getItem('berrySubmissionCascader')
  try {
    toBeNormalizedOptions = JSON.parse(toBeNormalizedOptions)
    if (!toBeNormalizedOptions) toBeNormalizedOptions = ['paid']
  } catch {
    toBeNormalizedOptions = ['paid']
  }

  const [cascaderFilterOptionsState, setFilterOptions] = useState(
    toBeNormalizedOptions,
  )

  const onFilterOptionsChange = (value) => {
    localStorage.setItem('berrySubmissionCascader', JSON.stringify(value))
    setFilterOptions(value)
  }

  const cascaderSettingExists = (setting) => {
    // return true
    if (
      cascaderFilterOptionsState &&
      typeof cascaderFilterOptionsState !== 'undefined'
    ) {
      let skb = cascaderFilterOptionsState.some((item) =>
        item.includes(setting),
      )
      if (skb) return true
    }
    return false
  }

  const deleteSubmission = async (doc) => {
    const docFound = await subsCollection.findOne(doc.id).exec();
    if(docFound) await docFound.remove()
  }

  let columns = []

  columns.push({
    title: 'Punkts',
    dataIndex: 'purchase_point_name',
    key: uuidv4(),
    width: 150,
    fixed: 'left',
    render: (point, record) => {
      return (
        <>
          <Link
            to={`/point/${record.point}`}
            style={{ color: '#001529', fontWeight: 'bold' }}
          >
            {record.purchase_point_name ? `${record.purchase_point_name}` : '?'}
          </Link>
          {/* {record.purchase_point_name ? `${record.purchase_point_name}` : '?'} */}
        </>
      )
    },
  })

  columns.push({
    title: 'Datums',
    dataIndex: 'date',
    key: 'date',
    width: 110,
    sorter: (a, b) => a.createdAt - b.createdAt,
    defaultSortOrder: 'descend',
    // sortDirections: 'descend',
    // sortOrder: 'descend'
  })

  if (cascaderSettingExists('sumToBePaid')) {
    columns.push({
      title: 'Kopā jāmaksā (€)',
      dataIndex: 'sumToBePaid',
      key: 'sumToBePaid',
    })
  }

  if (cascaderSettingExists('paid')) {
    columns.push({
      title: 'Izmaksātā nauda (€)',
      dataIndex: 'paidOutFunds',
      key: 'paidOutFunds',
    })
  }

  if (cascaderSettingExists('returnedFunds')) {
    columns.push({
      title: 'Atgriestais avanss (€)',
      dataIndex: 'returnedFunds',
      key: 'returnedFunds',
      render: (text, row, index) => validNumberFormatter(text),
    })
  }

  if (cascaderSettingExists('paidSalary')) {
    columns.push({
      title: 'Izmaksātā alga (€)',
      dataIndex: 'paidSalary',
      key: 'paidSalary',
      render: (text, row, index) => validNumberFormatter(text),
    })
  }

  if (cascaderSettingExists('point_funds_before')) {
    columns.push({
      title: 'Avansa atlikums sākumā (€)',
      dataIndex: 'point_funds_before',
      key: 'point_funds_before',
      render: (text, row, index) => {
        const calculated = pointFundsBefore(
            subs,
            row.point,
            // row.createdAt,
            DateTime.fromISO(row.date, { zone: 'Europe/Riga' }).startOf(
              'day',
              ).toSeconds()
          )
        return validNumberFormatter(calculated)
      },
    })
  }

  if (cascaderSettingExists('point_funds_after')) {
    columns.push({
      title: 'Avansa atlikums beigās (€)',
      dataIndex: 'point_funds_after',
      key: 'point_funds_after',
      render: (text, row, index) => {
        const calculated = pointFundsAfter(
            subs,
            row.point,
            DateTime.fromISO(row.date, { zone: 'Europe/Riga' }).startOf(
              'day',
              ).toSeconds()
          )
        return validNumberFormatter(calculated)
      },
    })
  }

  if (cascaderSettingExists('point_boxes_before')) {
    columns.push({
      title: 'Kastes punktā pirms',
      dataIndex: 'point_boxes_before',
      key: 'point_boxes_before',
      render: (text, row, index) => {
        const calculated = boxesBefore(
            subs,
            row.point,
            DateTime.fromISO(row.date, { zone: 'Europe/Riga' }).startOf(
              'day',
              ).toSeconds()
          )
        return validNumberFormatter(calculated)
      },
    })
  }

  if (cascaderSettingExists('point_boxes_after')) {
    columns.push({
      title: 'Kastes punktā pēc',
      dataIndex: 'point_boxes_after',
      key: 'point_boxes_after',
      render: (text, row, index) => {
        const calculated = boxesAfter(
            subs,
            row.point,
            DateTime.fromISO(row.date, { zone: 'Europe/Riga' }).startOf(
              'day',
              ).toSeconds()
          )
        return validNumberFormatter(calculated)
      },
    })
  }

  // if (cascaderSettingExists('driver_boxes_before')) {
  //   columns.push({
  //     title: 'Kastes (šofera) sākumā',
  //     dataIndex: 'driver_boxes_before',
  //     key: 'driver_boxes_before',
  //     render: (text, row, index) => {
  //       if (text) return <span>{text}</span>
  //       return '?'
  //     },
  //   })
  // }

  // if (cascaderSettingExists('driver_boxes_after')) {
  //   columns.push({
  //     title: 'Kastes (šofera) beigās',
  //     dataIndex: 'driver_boxes_after',
  //     key: 'driver_boxes_after',
  //     render: (text, row, index) => {
  //       if (text) return <span>{text}</span>
  //       return '?'
  //     },
  //   })
  // }

  columns.push({
    title: 'Status',
    dataIndex: 'syncStatus',
    key: 'syncStatus',
    width: 100,
    fixed: 'right',
    render: (status) => (
      <>
        {status && (
          <Tag color={status === 'synced' ? 'green' : 'volcano'} key={status}>
            {
              status === 'synced' ? 'IESNIEGTS' : 'NEIESNIEGTS'
              // status.toUpperCase()
            }
          </Tag>
        )}
      </>
    ),
  })

  columns.push({
    title: 'Dzēst',
    dataIndex: 'delete',
    key: 'delete',
    width: 100,
    fixed: 'right',
    render: (text, row, index) => {
      if(row.date === date) return (
      <DeleteConfirm handleDelete={() => deleteSubmission(row)} />
    )}
  })

  const uniquePointSubmissions = () => {
    let temp = []
    const pointsToSearch = point ? [points.find((p) => p.id == point)] : points
    pointsToSearch.forEach((point) => {
      const found = subs.find((sub) => sub.point == point.id)
      if (found) temp.push(found)
    })
    return temp
  }

  useEffect(() => {
    // settings local submissions
    if (points[0]) {
      const setValidSubmissions = async () => {
        if (viewType == 'points') {
          const normalized = await normalizeNewSubmissions(
            uniquePointSubmissions(),
            subs,
          )
          setSubmissions(normalized)
        } else {
          const normalized = await normalizeNewSubmissions(subs, subs)
          setSubmissions(normalized)
        }
      }
      setValidSubmissions()
    }
  }, [viewType, subs, points, point])

  const normalizeNewSubmissions = async (normalSubs, allSubs) => {
    console.log('subs111', normalSubs, allSubs)
    let list = []
    for (const sub of normalSubs) {
      const tempSub = {}
      tempSub.id = sub.id
      tempSub.purchase_point_name = points?.find(
        (item) => item.id == sub.point,
      )?.name
      tempSub.point = sub.point
      tempSub.boxesLeft = sub.boxesLeft
      tempSub.paidOutFunds = sub.paidOutFunds
      tempSub.sumToBePaid = sub.sumToBePaid()
      tempSub.paidSalary = sub.paidSalary
      tempSub.returnedFunds = sub.returnedFunds
      tempSub.date = sub.date
      tempSub.createdAt = sub.createdAt
      tempSub.products = sub.products
      tempSub.comment = sub.comment
      // tempSub.point_funds_before = pointFundsBefore(
      //   allSubs,
      //   sub.point,
      //   sub.createdAt,
      // )
      // tempSub.point_funds_after = pointFundsAfter(
      //   allSubs,
      //   sub.point,
      //   sub.createdAt,
      // )
      // tempSub.point_boxes_before = boxesBefore(
      //   allSubs,
      //   sub.point,
      //   sub.createdAt,
      // )
      // tempSub.point_boxes_after = boxesAfter(allSubs, sub.point, sub.createdAt)
      tempSub.syncStatus = sub.syncStatus
      list.push(tempSub)
    }
    console.log('subs111, this executed')
    return list
  }

  const handlePointChange = (p) => {
    if (!p) {
      setPoint(null)
    }
    setPoint(p)
  }

  const labelStyling = { fontWeight: '600' }

  const renderSubmissionDescription = (record) => {
    return (
      <Descriptions size="small" labelStyle={labelStyling} bordered>
        {record.comment && (
          <Descriptions.Item label="Komentārs" labelStyle={labelStyling}>
            <Paragraph
              ellipsis={{
                // rows,
                expandable: true,
                // suffix: '--William Shakespeare',
                onEllipsis: (ellipsis) => {},
              }}
              title={`${record.comment}`}
            >
              {record.comment}
            </Paragraph>
          </Descriptions.Item>
        )}
      </Descriptions>
    )
  }

  const pointName = () => {
    if (!point || !points) return null
    return points.find((curr) => curr.id == point)?.name
  }

  const currentTitle = () => {
    if (viewType === 'submissions') {
      return 'Iepirkumu pārskats'
    } else if (viewType === 'point') {
      return `${pointName()} pārskats`
    } else if (viewType === 'points') {
      return `Punktu pārskats`
    } else if (viewType === 'daily') {
      return day ? `Dienas pārskats (${day})` : 'Dienas pārskats'
    }
    return null
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }} size="middle">
      <PageHeader
        className="site-page-header"
        title={currentTitle()}
        style={{ background: 'white' }}
      />
      {/* Point:{point} */}
      {/* <Radio.Group
        defaultValue={viewType}
        onChange={handleTYpeChange}
        buttonStyle="solid"
      >
        <Radio.Button value="purchases">Iepirkumi</Radio.Button>
        <Radio.Button value="points">Punkti</Radio.Button>
      </Radio.Group> */}
    { viewType === 'submissions' && <div style={{ width: 170 }}>
      <Progress percent={syncPercentage} size="small" status={syncPercentage === 100 ? 'success' : 'active'} />
    </div>}
      <Form size="large" layout="inline">
        {points && (viewType === 'purchases' || viewType === 'daily') && (
          <Form.Item label="Datums">
            <DatePicker
              onChange={setCalendar}
              placeholder={day}
              allowClear={viewType === 'daily' ? false : true}
            />
          </Form.Item>
        )}
        {points && (viewType === 'purchases' || viewType === 'points') && (
          <Form.Item label="Punkts">
            <Select
              placeholder="Izvēlies punktu"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={handlePointChange}
              allowClear
            >
              {points
                ?.filter((point) =>
                  point.drivers.includes(parseInt(userDetails.uid)),
                )
                .map((item) => {
                  if (item?.id)
                    return (
                      <Option value={item.id} key={uuidv4()}>
                        {item.name}
                      </Option>
                    )
                })}
            </Select>
          </Form.Item>
        )}
      </Form>
      <span>Attēlot laukus:</span>
      <Cascader
        style={{ width: 533 }}
        options={cascaderFilterOptions}
        onChange={onFilterOptionsChange}
        multiple
        maxTagCount="responsive"
        defaultValue={toBeNormalizedOptions}
      />
      {/* {filteredSubmission() && <Button type="primary" icon={<DownloadOutlined />} onClick={(e) => handleSync()} >Sinhronizēt visu</Button>} */}
      {/* {(day || point) && (
        <Alert
          message={`Attēlota limitēta informācija (${day ? day : '-'}; ${
            point ? point : '-'
          }), balstoties uz iestatīto filtru.`}
          type="info"
          showIcon
          icon={<FilterOutlined />}
          // action={
          //   <Button
          //     onClick={() => {
          //       setDay(null)
          //       setPoint(null)
          //     }}
          //   >
          //     Noņemt filtrus
          //   </Button>
          // }
        />
      )} */}
      {viewType === 'daily' && submissions[0] && (
        <DailyStatsContainer
          submissions={submissions}
          transfers={transfers}
          weightings={weightings}
          dailySubs={dailySubs(submissions)}
          date={day}
        />
      )}

      {viewType === 'point' && submissions[0] && (
        <PointStatisticsExtensive submissions={filteredSubmission()} />
      )}

      {(filteredSubmission() && viewType === 'submissions') && 
        <span>{`Kopā ${filteredSubmission().length} iepirkumi, no kuriem ${submissionCount()} ir iesniegti.`}</span>
        }
        <Table
          dataSource={filteredSubmission()}
          columns={columns}
          rowKey="id"
          // sortDirections="ascend"
          expandable={{
            expandedRowRender: (record) => {
              return (
                <div style={{ margin: 0 }}>
                  {renderSubmissionDescription(record)}
                  {
                    <ExpandedRow
                      key={record.id}
                      products={record.products}
                      style={productStyle}
                    ></ExpandedRow>
                  }
                </div>
              )
            },
            rowExpandable: (record) =>
              record.comment || record?.products?.length !== 0,
          }}
          scroll={{ x: 1300, y: 450 }}
        />
    </Space>
  )
}
