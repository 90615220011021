import React, { useState, useEffect } from 'react'
import { Typography, Table } from 'antd'
import { Link } from 'react-router-dom'
import { validNumberFormatter } from '../../utils/utils'
import { getPointsLocal } from '../db/db'

const { Title } = Typography

export const PointsOverviewPage = (props) => {
  const [Points, setPoints] = useState(null)

  const columns = [
    {
      title: 'Punkts',
      dataIndex: 'purchase_point',
      render: (point, record) => (
        <>
          {/* <Link to={'/points/' + record.tid}>{record.purchase_point}</Link> */}
          {record.purchase_point}
        </>
      ),
      //   key: 'name',
    },
    {
      title: 'Avanss punktā',
      dataIndex: 'field_funds_at_point',
      render: (text, row, id) => validNumberFormatter(text),
    },
    {
      title: 'Kastes',
      dataIndex: 'field_boxes_at_point',
      render: (text, row, id) => validNumberFormatter(text),
    },
  ]

  useEffect(() => {
    const getPoints = () => {
      return getPointsLocal()
        .then((points) => {
          setPoints(points)
        })
        .catch((error) => console.log(error))
    }

    getPoints()
  }, [])

  if (!Points) return <p>Nav datu...</p>

  return (
    <>
      <Title level={4}>Punktu Kopsavilkums</Title>
      <Table
        dataSource={Points}
        columns={columns}
        pagination={{ pageSize: 100 }}
      />
    </>
  )
}
