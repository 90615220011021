import React, { useEffect, useState } from 'react'
import { useRxCollection } from 'rxdb-hooks'
import {
  Form,
  Spin,
  Button,
  Input,
  InputNumber,
  Typography,
  message,
  Table,
  Space,
  Tag,
  Select,
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { validNumberFormatter } from '../../utils/utils'
import { publishFuelRecord } from '../../utils/Api'
import { useQuery } from 'react-query'
import { fetchFuelRecords } from '../../utils/Api'
import { customAlphabet } from 'nanoid'
import { DeleteConfirm } from "../ui/DeleteConfirm";

import { useStore } from '../../state/store'
const { Title } = Typography
const { Option } = Select
const { DateTime } = require('luxon')

export const FuelPage = () => {
  const transport = useStore((state) => state.transport)
  const fuel = useStore((state) => state.fuel).sort((a, b) => b.createdAt - a.created)
  const collection = useRxCollection('fuel')

  const myDateTime = DateTime.now()
  const date = myDateTime.toISODate()

  // const [selectedTransport, setSelectedTransport] = useState(null)

  console.log('fuel123', fuel)

  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)
  console.log('userDetails123', userDetails)

  const getTransportName = (id) => {
    if (id && transport) {
      return transport.find((item) => item.id == id)?.name
    }
  }

  const deleteFuelRecord = async (doc) => {
    const docFound = await collection.findOne(doc.id).exec();
    if(docFound) await docFound.remove()
  }

  console.log('transport123', transport)

  const columns = [
    {
      title: 'Datums',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Litri',
      dataIndex: 'liters',
      key: 'liters',
    },
    {
      title: 'Summa',
      dataIndex: 'sum',
      key: 'sum',
    },
    {
      title: 'Transports',
      dataIndex: 'transport',
      key: 'transport',
      render: (text) => getTransportName(text),
    },
    {
      title: 'Odometrs',
      dataIndex: 'odometer',
      key: 'odometer',
    },
    {
      title: 'Čeka nr.',
      dataIndex: 'receipt',
      key: 'receipt',
    },
    {
      title: 'Status',
      dataIndex: 'syncStatus',
      key: 'syncStatus',
      width: 100,
      fixed: 'right',
      render: (status) => (
        <>
          {status && (
            <Tag color={status === 'synced' ? 'green' : 'volcano'} key={status}>
              {status === 'synced' ? 'IESNIEGTS' : 'NEIESNIEGTS'}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Dzēst',
      dataIndex: 'delete',
      key: 'delete',
      width: 100,
      fixed: 'right',
      render: (text, row, index) => {
        if(row.date === date) return (
        // <Button size="small" icon={<DeleteOutlined />} onClick={() => deleteFuelRecord(row)}>Dzēst</Button>
        <DeleteConfirm handleDelete={() => deleteFuelRecord(row)} />
      )}
    }
  ]

  const onFinish = (formData) => {
    // if (!Number.isInteger(formData.odometer)) {
    //   message.error('This is an error message')
    //   return
    // }
    // publishFuelRecord(formData)

    const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 15)
    let id = nanoid()

    console.log('formData111', formData)

    collection.insert({
      id: id,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      liters: validNumberFormatter(formData.liters),
      sum: validNumberFormatter(formData.sum),
      odometer: validNumberFormatter(formData.odometer),
      receipt: formData.receipt,
      transport: validNumberFormatter(formData.transport),
      date: date,
      syncStatus: 'unsynced',
    })
  }

  // const onValuesChange = () => {
  //   return
  // }

  // function handleTransportChange(value) {
  //   setSelectedTransport(value)
  // }

  const displayTransportOptions = () => {
    return transport.map((item) => {
      if (item.owners?.find((owner) => owner == userDetails.uid)) {
        return <Option value={item.id}>{item.name}</Option>
      }
    })
  }

  return (
    <>
      <Space size="large" direction="vertical">
        <Title level={2}>Degvielas uzpildes atskaites</Title>
        <Form
          name="fuel_form"
          onFinish={onFinish}
          autoComplete="off"
          size="large"
          layout="inline"
          // labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            name="transport"
            label="Transports"
            rules={[{ required: true, message: 'Obligāts lauks!' }]}
          >
            <Select
              style={{ width: 120 }}
              // onChange={handleTransportChange}
            >
              {displayTransportOptions()}
            </Select>
          </Form.Item>
          <Form.Item
            name="liters"
            label="Litri"
            rules={[{ required: true, type: 'number', min: 0, message: 'Obligāts lauks!' }]}
          >
            <InputNumber
              type="number"
              pattern="[0-9]*"
              min={1}
            />
          </Form.Item>
          <Form.Item
            name="sum"
            label="Summa"
            rules={[{ required: true, type: 'number', min: 0, message: 'Obligāts lauks!' }]}
          >
            <InputNumber
              type="number"
              pattern="[0-9]*"
              min={1}
            />
          </Form.Item>
          <Form.Item
            name="odometer"
            label="Odometra rādījums"
            rules={[{ required: true, type: 'number', min: 0, message: 'Obligāts lauks!' }]}
          >
            <InputNumber
              type="number"
              pattern="[0-9]*"
              min={1}
            />
          </Form.Item>
          <Form.Item
            name="receipt"
            label="Čeka nr."
            rules={[{ required: true, message: 'Obligāts lauks!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Iesniegt
            </Button>
          </Form.Item>
        </Form>
        {fuel ? (
          <Table dataSource={fuel} columns={columns} />
        ) : (
          <Spin size="large" />
        )}
      </Space>
    </>
  )
}
