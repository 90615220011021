import React, { useState, useEffect } from 'react'
import axios from 'axios'
// import create from 'zustand'
// import 'antd/dist/antd.css'
// https://ant.design/docs/react/customize-theme-variable
import './modified.css'
import './App.css'
import { Layout, Menu, Row, Col, Tag } from 'antd'
import { MainWrapper } from './MainWrapper'
import {
  HomeOutlined,
  ShoppingCartOutlined,
  HistoryOutlined,
  CalendarOutlined,
  CarOutlined,
  RocketOutlined,
  SyncOutlined,
  PercentageOutlined,
  EuroCircleOutlined,
  InsertRowAboveOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Spin } from 'antd'
import { Offline } from 'react-detect-offline'
import { Alert } from 'antd'
import create from 'zustand'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from 'react-router-dom'
import { useIsFetching } from 'react-query'
import { Provider } from 'rxdb-hooks'

import { LandingPage } from './components/LandingPage'
import Login from './components/user/Login'
import { Secret } from './components/Secret'
import { ProtectedRoute } from './utils/protected.route'
import { AuthContext } from './utils/auth-context'
import { UserHeader } from './components/user/UserHeader'
import { PurchasePage } from './components/PurchasePage'
import { PointPage } from './components/points/PointPage'
import { SubmissionOverview } from './components/submissions/SubmissionOverview'
import { SettingsPage } from './components/settings/SettingsPage'
import { PointsOverview } from './components/points/PointsOverviewPage'
import { FuelPage } from './components/fuel/FuelPage'
import { getSidebarCollapse, setSidebarCollapse } from './components/db/db'
import { postSubmissions } from './components/submissions/SubmissionOverview'
import { useRxData } from 'rxdb-hooks'
import { QueryClient, QueryClientProvider, useQuery } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { getPointsLocal, getSubmissions } from './components/db/db'
import { PointsOverviewPage } from './components/points/PointsOverviewPage'
import { WeightingOverviewPage } from './components/warehouse/WeightingOverviewPage'
import { TransferOverviewPage } from './components/warehouse/TransferOverviewPage'
import UpdateNotification from './components/updates/UpdateNotification'
import { replicateRxCollection } from 'rxdb/plugins/replication'
import { initialize } from './components/db/Database'
import { RxReplicationState } from 'rxdb'

const { Content, Footer, Sider } = Layout

export const App = () => {
  // const handleLogout = () => {
  //   props.history.push("/secret");
  // };

  const jwt = localStorage.getItem('jwt_berry')
  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)

  const [db, setDb] = useState()

  const isFetching = useIsFetching()

  const getPoint = (id) => {
    return getPointsLocal(id)
      .then((points) => {
        //
        return points[0]
      })
      .catch((error) => console.log(error))
  }

  const getEnvTag = () => {
    switch (process.env.REACT_APP_BACKEND) {
      case 'dev':
        return <Tag color='orange'>{process.env.REACT_APP_BACKEND}</Tag>
      case 'prod':
        return <Tag color='#002140'>{process.env.REACT_APP_BACKEND}</Tag>
      default:
        return <Tag>{process.env.REACT_APP_BACKEND}</Tag>
    }
  }

  const onCollapse = async (collapsed) => {
    setState({ collapsed })
    setSidebarCollapse(collapsed)
  }

  const setLoading = () => {
    setState({
      loading: !state.loading,
    })

    setState({
      ...state,
      loading: !state.loading,
    })
  }

  const setAvatarUrl = (url) => {
    setState({ ...state, avatar_url: url })
  }

  const setUsername = (username) => {
    setState({
      ...state,
      username: username,
    })
  }

  const setLoggedIn = (status) => {
    setState({
      ...state,
      loggedIn: status,
    })
  }

  const [state, setState] = useState({
    collapsed: null,
    setLoading: setLoading,
    avatar_url:
      'https://www.shareicon.net/data/128x128/2017/01/06/868320_people_512x512.png',
    setAvatarUrl: setAvatarUrl,
    username: null,
    setUsername: setUsername,
    loggedIn: false,
    setLoggedIn: setLoggedIn,
  })

  useEffect(() => {
    async function collapseHandling() {
      let status = await getSidebarCollapse()
      if (status && status[0]) {
        setState({ collapsed: status[0].contents })
      }
    }

    collapseHandling()

    // Uncomment this for automatic sync
    // setInterval(async () => {
    //
    //   // const submissions = await postSubmissions()
    //   getSubmissions().then(
    //     async items => {
    //       // other way
    //       // normalizeSubmission(items).then(
    //       //   result => setSubmissions(result)
    //       // )
    //       //
    //       const normalized = await normalizeSubmission(items);
    //       setSubmissions(normalized)
    //       //
    //       //
    //       let toBeSynced = []
    //       if(normalized[0]) {
    //
    //         toBeSynced = [...normalized]
    //         //
    //         postSubmissions(toBeSynced)
    //       }
    //     }
    //   )
    // }, 5000);
  }, [])

  useEffect(() => {
    // Notice that RxDB instantiation is asynchronous;
    // until db becomes available consumer hooks that depend
    // on it will still work, absorbing the delay by
    // setting their state to isFetching:true
    const initDB = async () => {
      const _db = await initialize()
      setDb(_db)
    }
    initDB()
  }, [])

  return (
    <Provider db={db}>
      <AuthContext.Provider value={state}>
        <MainWrapper>
          <Layout style={{ minHeight: '100vh' }}>
            {/* <UpdateNotification /> */}
            <Router>
              <Sider
                collapsible
                collapsed={state.collapsed}
                onCollapse={onCollapse}
              >
                {/* <div className="logo" /> */}
                <Menu theme="dark" mode="inline">
                  <Menu.Item key="1" icon={<HomeOutlined />}>
                    <Link to={'/'}>Sākums</Link>
                  </Menu.Item>
                  <Menu.Item key="2" icon={<ShoppingCartOutlined />}>
                    <Link to={'/purchase'}>Jauns iepirkums</Link>
                  </Menu.Item>
                  <Menu.Item key="3" icon={<InsertRowAboveOutlined />}>
                    <Link to={'/submissions'}>Visi iepirkumi</Link>
                  </Menu.Item>
                  <Menu.Item key="4" icon={<HistoryOutlined />}>
                    <Link to={'/points'}>Punktu pārskats</Link>
                  </Menu.Item>
                  <Menu.Item key="5" icon={<CalendarOutlined />}>
                    <Link to={'/daily'}>Dienu pārskats</Link>
                  </Menu.Item>
                  {/* <Menu.Item key="6" icon={<PercentageOutlined />}>
                    <Link to={'/weighting'}>Svēršanu pārskats</Link>
                  </Menu.Item> */}
                  <Menu.Item key="7" icon={<EuroCircleOutlined />}>
                    <Link to={'/transfers'}>Noliktavas nauda</Link>
                  </Menu.Item>
                  <Menu.Item key="8" icon={<CarOutlined />}>
                    <Link to={'/fuel'}>Degviela</Link>
                  </Menu.Item>
                  <Menu.Item key="9" icon={<SettingOutlined />}>
                    <Link to={'/settings'}>Iestatījumi</Link>
                  </Menu.Item>
                  <Menu.Item key="10" icon={<RocketOutlined />}>
                    <Link to={'#'}>v. 1.85 | {getEnvTag()}</Link>
                  </Menu.Item>
                  <Menu.Item
                    key="11"
                    icon={isFetching ? <SyncOutlined spin /> : <SyncOutlined />}
                  >
                    <Link to={'#'}></Link>
                  </Menu.Item>
                </Menu>
              </Sider>
              <Layout className="site-layout">
                <UserHeader />
                <Content
                  style={{
                    margin: '0 16px',
                    backgroundColor: 'white',
                    margin: '0em',
                  }}
                >
                  <div
                    className="site-layout-background"
                    style={{ padding: 24, minHeight: 360 }}
                  >
                    <Offline>
                      <Alert
                        message='Savienojums nestabils. Ievadītie iepirkumi tīkla nestabilitātes gadījumā tiks saglabāti sadaļā "Sinhronizācija".'
                        type="warning"
                        showIcon
                      />
                    </Offline>
                    <Row justify="center">
                      <Col span={24}>
                        <Switch>
                          <ProtectedRoute
                            exact
                            path="/"
                            component={LandingPage}
                          />
                          <ProtectedRoute
                            exact
                            path="/purchase"
                            component={PurchasePage}
                          />
                          <ProtectedRoute
                            exact
                            path="/fuel"
                            component={FuelPage}
                          />
                          <ProtectedRoute
                            exact
                            path="/purchase/:id"
                            component={PurchasePage}
                          />
                          <ProtectedRoute
                            exact
                            path="/submissions"
                            // type={'purchases'}
                            component={SubmissionOverview}
                          />
                          <ProtectedRoute
                            exact
                            path="/points"
                            // viewType="points"
                            component={SubmissionOverview}
                          />
                          <ProtectedRoute
                            path="/point/:id"
                            // viewType="points"
                            component={SubmissionOverview}
                          />
                          <ProtectedRoute
                            exact
                            path="/daily"
                            // viewType="daily"
                            component={SubmissionOverview}
                          />
                          <ProtectedRoute
                            exact
                            path="/weighting"
                            component={WeightingOverviewPage}
                          />
                          <ProtectedRoute
                            exact
                            path="/transfers"
                            component={TransferOverviewPage}
                          />
                          <ProtectedRoute
                            exact
                            path="/points/:id"
                            component={PointPage}
                          />
                          <ProtectedRoute
                            exact
                            path="/settings"
                            component={SettingsPage}
                          />
                          <Route exact path="/login">
                            <Login />
                          </Route>
                          <ProtectedRoute
                            exact
                            path="/secret"
                            component={Secret}
                          />
                        </Switch>
                      </Col>
                    </Row>
                  </div>
                </Content>
                {/* <Footer style={{ textAlign: 'center' }}>©2021</Footer> */}
              </Layout>
            </Router>
          </Layout>
        </MainWrapper>
      </AuthContext.Provider>
    </Provider>
  )
}

export default withRouter(App)
