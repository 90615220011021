import axios from 'axios'
class Auth {
  constructor() {
    this.authenticated = false
  }

  login(cb) {
    //   this.authenticated = true;
    //   cb();
    const jwt = localStorage.getItem('jwt_berry')
    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + jwt,
    }

    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}user/login_status?_format=json`,
        {
          headers: headers,
        },
      )
      .then((response) => {
        //
        if (response.data === 1) {
          this.authenticated = true
          cb()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  async logout(cb) {
    localStorage.removeItem('jwt_berry')
    localStorage.removeItem('user')
    this.authenticated = false
    // localStorage.clear()
    cb()
  }

  isAuthenticated() {
    const jwt = localStorage.getItem('jwt_berry')
    if (jwt) {
      this.authenticated = true
      return true
    } else {
      return false
    }
  }
}

export default new Auth()
