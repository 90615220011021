export const roundNumber = (inputNunber) => {
  return (Math.round(inputNunber * 100) / 100).toFixed(2)
}

export const percentageDiff = (quantity, quantity_driver) => {
  if (!quantity || !quantity_driver) return false

  let percentage = 100 - (100 * quantity_driver) / quantity
  // console.log('percentage', percentage)
  // const percentageObject = {
  //   type: percentage > 0 ? 'deficit' : 'surplus',
  //   amount: roundNumber(Math.abs(percentage)),
  // }

  const percentageObject = {}

  if (percentage < 0) {
    percentageObject.type = 'surplus'
    percentageObject.amount = Math.abs(percentage)
    percentageObject.formatted = `<span style='color: green;'>+${roundNumber(
      Math.abs(percentage),
    )}</span>`
  } else if (percentage > 0) {
    percentageObject.type = 'deficit'
    percentageObject.amount = Math.abs(percentage)
    percentageObject.formatted = `<span style='color: red;'>-${roundNumber(
      Math.abs(percentage),
    )}</span>`
  } else {
    percentageObject.type = 'none'
    percentageObject.amount = 0
    percentageObject.formatted = `<span>Ø</span>`
  }

  return percentageObject
}

export const weightDiff = (quantity, quantity_driver) => {
  if (!quantity || !quantity_driver) return false

  const weightDiffObject = {}

  if (quantity_driver > quantity) {
    weightDiffObject.type = 'surplus'
    weightDiffObject.amount = quantity_driver - quantity
    weightDiffObject.formatted = `<span style='color: green;'>+${roundNumber(
      quantity_driver - quantity,
    )}</span>`
  } else if (quantity_driver < quantity) {
    weightDiffObject.type = 'deficit'
    weightDiffObject.amount = quantity - quantity_driver
    weightDiffObject.formatted = `<span style='color: red;'>-${roundNumber(
      quantity - quantity_driver,
    )}</span>`
  } else {
    weightDiffObject.type = 'none'
    weightDiffObject.amount = 0
    weightDiffObject.formatted = `<span>Ø</span>`
  }

  return weightDiffObject
}

export const validNumberFormatter = (number) => {
  if (number == null || number === undefined) {
    return 0
  }
  if (isNaN(number)) return '?'
  return Number(number)
}
