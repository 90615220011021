import React, { useState } from 'react'
import { Switch, Button, PageHeader } from 'antd'
import { saveAs } from 'file-saver'
import useStore from '../../state/store'
import { useRxDB } from 'rxdb-hooks'

export const SettingsPage = () => {
  const db = useRxDB()
  const productStyle = useStore((state) => state.productStyle)
  const changeDisplaySetting = useStore((state) => state.setProductStyle)

  const setProductStyle = (checked, event) => {
    changeDisplaySetting(checked)
  }

  const exportSettings = () => {
    db.exportJSON().then((json) => {
      const blob = new Blob([JSON.stringify(json)], {
        type: 'text/plain;charset=utf-8',
      })
      saveAs(blob, `berry_settings_${Date.now()}.json`)
    })
  }

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="Iestatījumi"
        style={{ background: 'white' }}
      />
      <span>Kartiņu stils:</span>
      <Switch
        defaultChecked={productStyle}
        onChange={setProductStyle}
      /> <br /> <br />
      <Button onClick={() => exportSettings()}>Eksportēt iestatījums</Button>
    </>
  )
}
