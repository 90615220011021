export const weightingSchema = {
  title: 'Weighting schema',
  description: 'Describes weighting',
  version: 1,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    createdAt: {
      type: 'number',
    },
    updatedAt: {
      type: 'number',
    },
    date: {
      type: 'string',
    },
    manager: {
      type: 'string',
    },
    products: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'number',
            minimum: 0,
          },
          quantity: {
            type: 'number',
            minimum: 0,
          },
          quantityPoint: {
            type: 'number',
            minimum: 0,
          },
          diffKg: {
            type: 'number',
          },
          diffPercent: {
            type: 'number',
          },
        },
      },
    },
  },
  required: ['id'],
}
