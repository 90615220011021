import React from 'react'
import {
    Button,
    Popconfirm,
    Tooltip
  } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
export const DeleteConfirm = (props) => {

  const {handleDelete} = props;

  const [visible, setVisible] = React.useState(false)
  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const showPopconfirm = () => {
    setVisible(true)
  }

  const handleOk = async () => {
    setConfirmLoading(true)
    setVisible(false)
    await handleDelete()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Popconfirm
    title="Vai tiešām vēlaties dzēst šo ierakstu?"
    visible={visible}
    onConfirm={handleOk}
    okButtonProps={{ loading: confirmLoading }}
    onCancel={handleCancel}
    okText={'Jā'}
  >
      <Tooltip>
        <Button size="small" icon={<DeleteOutlined />} onClick={() => showPopconfirm()}>Dzēst</Button>
      </Tooltip>
    </Popconfirm>
  )
}

export default DeleteConfirm