import React from 'react'
import { Descriptions, Empty, Typography } from 'antd'
import { InboxOutlined, EuroCircleOutlined } from '@ant-design/icons'
import { pointFundsBefore, boxesBefore } from '../db/dbQueries'
import { useStore } from '../../state/store'

export const PointStatistics = (props) => {
  const subs = useStore((state) => state.submissions)

  return (
    <>
      <Descriptions title="Punkta pārskats" bordered>
        <Descriptions.Item
          label={
            <>
              <EuroCircleOutlined /> Avansa atlikums
            </>
          }
        >
          {pointFundsBefore(subs, parseInt(props.point.id, 10), Date.now()) ||
            '--'}{' '}
          <span>EUR</span>
        </Descriptions.Item>
        <Descriptions.Item
          label={
            <>
              <InboxOutlined /> Kastes punktā
            </>
          }
        >
          {boxesBefore(subs, parseInt(props.point.id, 10), Date.now()) || '-'}
          <span> gb.</span>
        </Descriptions.Item>
      </Descriptions>
    </>
  )
}
