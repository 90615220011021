import React from 'react'
import create from 'zustand'
import { useRxData } from 'rxdb-hooks'
import { useStore } from './state/store'

export const MainWrapper = (props) => {
  const queryConstructorAll = (collection) => collection.find()

  const { result: submissionsIncoming, isFetching } = useRxData(
    'submissions',
    queryConstructorAll,
  )

  const { result: productsIncoming } = useRxData(
    'products',
    queryConstructorAll,
  )

  const { result: pointsIncoming } = useRxData('points', queryConstructorAll)

  const { result: transfersIncoming } = useRxData(
    'transfers',
    queryConstructorAll,
  )

  const { result: weightingIncoming } = useRxData(
    'weighting',
    queryConstructorAll,
  )

  const { result: transportIncoming } = useRxData(
    'transport',
    queryConstructorAll,
  )

  const { result: fuelIncoming } = useRxData('fuel', queryConstructorAll)

  const submissionsSorted = submissionsIncoming.sort(
    (a, b) => b.createdAt - a.createdAt,
  )

  useStore.setState({ submissions: submissionsSorted })
  useStore.setState({ points: pointsIncoming })
  useStore.setState({ products: productsIncoming })
  useStore.setState({ transfers: transfersIncoming })
  useStore.setState({ weighting: weightingIncoming })
  useStore.setState({ transport: transportIncoming })
  useStore.setState({ fuel: fuelIncoming })

  // const subsNew = useStore((state) => state.submissions)

  //   const useStore = create((set) => ({
  //     submissions: submissions,
  //     removeSubmissions: () => set({ submissions: null }),
  //   }))

  return <>{props.children}</>
}

export default MainWrapper
