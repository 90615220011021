import React, { useState, useEffect } from 'react'
import { Typography, Table, Spin, Empty } from 'antd'
import { Link } from 'react-router-dom'
import { getPointsLocal } from '../db/db'
import { fetchDailyPurchaseWeighting } from '../../utils/Api'
import { getProductsLocal } from '../db/db'
import { ProductSymbol } from '../submissions/ProductSymbol'
import { WeightingRecordTable } from './WeightingRecordTable'
import useStore from '../../state/store'
import { dailySubmissions } from '../db/dbQueries'
import { useQuery } from 'react-query'
import { useRxData } from 'rxdb-hooks'

const { Title } = Typography

export const WeightingOverviewPage = (props) => {
  // const {
  //   isLoading: isLoadingDailyWeighting,
  //   isError: isErrorDailyWeighting,
  //   isSuccess: isSuccessDailyWeighting,
  //   data: dataDailyWeighting,
  //   error: errorDailyWeighting,
  // } = useQuery('dailyPurchaseWeighting', () => fetchDailyPurchaseWeighting())

  // const [productNames, setProductNames] = useState({ productNames: {} })

  const queryConstructorWeighting = (collection) => collection.find()

  const { result: weightings, weightingIsFetching } = useRxData(
    'weighting',
    queryConstructorWeighting,
  )

  const queryConstructorProducts = (collection) => collection.find()

  const { result: products, productsIsFetching } = useRxData(
    'products',
    queryConstructorProducts,
  )

  const submissions = useStore((state) => state.submissions)

  useEffect(() => {
    console.log('change trigger')
  }, [products, productsIsFetching])

  // if (isLoadingDailyWeighting) return <Spin />

  if (!weightings || weightings.length === 0) return <Empty />

  const columns = [
    {
      title: 'Datums',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Noliktavas pārzinis',
      dataIndex: 'manager',
      key: 'manager',
    },
  ]

  return (
    <>
      <Title level={4}>Svēršanu Kopsavilkums</Title>
      <Table
        dataSource={weightings}
        columns={columns}
        rowKey={'id'}
        pagination={{ pageSize: 100 }}
        expandable={{
          // expandedRowRender: (record) => WeightingProductRow(record.products),
          expandedRowRender: (record) => {
            console.log('record9', record)
            return (
              <WeightingRecordTable
                weightedProducts={record.products}
                products={products}
                submissions={dailySubmissions(submissions, record.date)}
              />
            )
          },
          rowExpandable: (record) => true,
        }}
      />
    </>
  )
}
