import React from 'react'
import { useParams } from 'react-router-dom'
import { Spin, Typography } from 'antd'
import { useQuery } from 'react-query'
import { PointStatistics } from './PointStatistics'

const { Title } = Typography

export const PointPage = () => {
  return <></>
}
