import React, { useState, useEffect, useContext } from 'react'
import {
  Form,
  Button,
  Space,
  Input,
  InputNumber,
  Select,
  Typography,
  Steps,
  Switch,
  Tag,
  Row,
  Col,
  notification,
} from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { useRxData } from 'rxdb-hooks'
import {
  setPointsLocal,
  setProductsLocal,
  getPointsLocal,
  getProductsLocal,
  totalCountForDay,
} from './db/db'
import { SubmissionPreview } from './submissions/SubmissionPreview'
import { SubmissionResult } from './submissions/SubmissionResult'
import { AveragePrice } from './submissions/AveragePrice'
import { v4 as uuidv4 } from 'uuid'
import { DateTime } from 'luxon'
import { useQuery } from 'react-query'
import { validNumberFormatter } from '../utils/utils'
import { PointStatistics } from './points/PointStatistics'
import { AuthContext } from '../utils/auth-context'
import { weightDiff, percentageDiff } from './../utils/utils'

import {
  HomeOutlined,
  ShoppingCartOutlined,
  HistoryOutlined,
  CalendarOutlined,
  CarOutlined,
  RocketOutlined,
} from '@ant-design/icons'


const { Option } = Select
const { Title } = Typography
const { Step } = Steps
const { TextArea } = Input

export const CategoryContext = React.createContext({
  modalVisible: 'here',
  hideModal: () => {},
})

export const PurchasePage = (props) => {
  const jwt = localStorage.getItem('jwt_berry')
  const [terms, setTerms] = useState({ terms: {} })
  const [avgPrice, setAvgPrice] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [submissionData, setSubmissionData] = useState(null)
  const [resultType, setResultType] = useState(null)
  const [commentStatus, setCommentStatus] = useState(false)
  const { loggedIn } = useContext(AuthContext)

  const setPreviewSubmitResult = () => {
    setCurrentStep(3)
    setResultType({
      type: 'success',
      message: 'Produkta iepirkums veiksmīgi iesniegts sinhronizēšanai!',
    })
  }

  const queryConstructor = (collection) => collection.find()
  const queryConstructorProducts = (collection) => collection.find()

  const { result: points, isFetching: pointsIsFetching } = useRxData(
    'points',
    queryConstructor,
  )

  const { result: products, isFetching: productsIsFetching } = useRxData(
    'products',
    queryConstructorProducts,
  )

  const next = () => {
    setCurrentStep(currentStep + 1)
  }

  const prev = () => {
    setCurrentStep(currentStep - 1)
  }

  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)

  const sumToBePaid = () => {
    if (!avgPrice) return
    let s = 0

    for (const product of avgPrice) {
      if (product.sum) {
        s = s + product.sum
      }
    }

    return validNumberFormatter(s)
  }

  const totalBoxesLeft = () => {
    if (!avgPrice) return
    let s = 0

    for (const item of avgPrice) {
      if (item.boxes_taken) {
        s = s + item.boxes_taken
      }
    }

    return validNumberFormatter(s)
  }

  const totalBoxesTaken = () => {
    if (!avgPrice) return
    let totalBoxesTakenCount = 0
    for (const product of avgPrice) {
      totalBoxesTakenCount = totalBoxesTakenCount + product.boxes_taken
    }
    return totalBoxesTakenCount
  }

  const openNotificationWithIcon = (type, title, description) => {
    notification[type]({
      message: title,
      description,
    })
  }

  const preparePreview = (values) => {
    if (values?.paid_out_funds || !isNaN(sumToBePaid())) {
      if (values?.paid_out_funds && !isNaN(sumToBePaid())) {
        if (values.paid_out_funds < sumToBePaid()) {
          openNotificationWithIcon(
            'warning',
            'Kļūda!',
            'Ja notiek avansa izmaksa no punkta, lūdzu ievadiet to laukā "Avansa atmaksa" nevis norādiet zemāku izmaksāto summu!',
          )
          return
        }
      }
    }

    if (values.comment_content) {
      values.comment_content = values.comment_content.trim()
    }
    const now = DateTime.now()
    const timestamp = now.toMillis()
    const date = now.toISODate()
    values.timestamp = timestamp
    values.date = date
    values.sumToBePaid = sumToBePaid()
    values.totalBoxesTaken = totalBoxesTaken()

    setSubmissionData(values)
    next()
  }

  const showPreview = () => {
    if (submissionData && currentStep === 1) {
      return true
    }
    return false
  }

  const onValuesChange = (changed, all) => {
    if (!Array.isArray(all.products) || !all.products.length) return
    let m = all.products.map((product, i) => {
      return {
        i: i,
        sum: product?.sum,
        weight: product?.sum,
        boxes_taken: product?.boxes_taken,
        resultAvgPrice: AveragePrice({
          sum: product?.sum,
          weight: product?.quantity,
        }),
        resultWeightDiffKg: weightDiff(
          product?.quantity,
          product?.quantity_driver,
        ),
        resultWeightDiffPercentage: percentageDiff(
          product?.quantity,
          product?.quantity_driver,
        ),
      }
    })
    if (m) setAvgPrice(m)
    if (all?.products) {
      let seen = new Set()
      var hasDuplicates = all.products.some(function (currentObject) {
        return seen.size === seen.add(currentObject?.product).size
      })
      if (hasDuplicates) {
        setProductStatus('error')
      } else {
        setProductStatus(null)
      }
    }
  }

  const [currentPoint, setCurrentPoint] = useState(null)
  const [pointStatus, setPointStatus] = useState(null)
  const [productStatus, setProductStatus] = useState(null)
  const [canProceed, setCanProceed] = useState(false)

  useEffect(() => {
    if (pointStatus === 'error' || productStatus === 'error' || !currentPoint) {
      setCanProceed(false)
    } else {
      setCanProceed(true)
    }
  }, [pointStatus, productStatus])

  const onPointChange = (p) => {
    // return
    // let point = dataPoints?.data
    if (points) {
      const current = points.filter((point) => point.id === p)
      if (current) setCurrentPoint(current)
      setPointStatus('success')

      // const getPurchaseCount = async (pointId) => {
      //   const count = await totalCountForDay(pointId)
      //   return count
      // }

      // getPurchaseCount(current[0]?.tid).then((count) => {
      //   if (count > 0) {
      //     setPointStatus('error')
      //   } else {
      //     setPointStatus('success')
      //   }
      // })
    }
  }

  function onCommentSwitch(checked) {
    setCommentStatus(checked)
  }

  const displayWeightDiff = (pointWeight, driverWeight) => {}

  const isDriversPoint = (point) => {
    const pointOwners = point?.point_driver
    if (pointOwners) {
      return pointOwners.find((item) => item === userDetails.uid)
    }
  }

  return (
    <>
      <Space size="large" direction="vertical" style={{ width: '100%' }}>
        <Title level={2}>Jauns iepirkums</Title>
        {currentPoint && currentStep === 0 && (
          <PointStatistics
            point={currentPoint[0]}
            subData={showPreview() && submissionData}
          />
        )}
        <Steps size="small" current={currentStep}>
          <Step title="Ievade" />
          <Step title="Priekšskatījums" />
          <Step title="Iesniegts" />
        </Steps>

        <Form
          className={currentStep !== 0 && 'hidden'}
          name="dynamic_form_nest_item"
          onFinish={preparePreview}
          autoComplete="off"
          onValuesChange={onValuesChange}
          size="large"
          layout="vertical"
        >
          <Form.Item
            label="Punkts:"
            name={'point'}
            fieldKey={'point'}
            hasFeedback
            validateStatus={pointStatus}
            help={
              pointStatus === 'error' &&
              'Šajā datumā šim punktam jau eksistē orderis!'
            }
            rules={[{ required: true, message: 'Obligāts lauks!' }]}
          >
            <Select placeholder="Izvēlies punktu" onChange={onPointChange}>
              {points &&
                points
                  ?.filter((point) =>
                    point.drivers.includes(parseInt(userDetails.uid)),
                  )
                  .map((point) => {
                    return (
                      <Option value={point.id} key={point.id}>
                        {point.name}
                      </Option>
                    )
                  })}
            </Select>
          </Form.Item>
          {currentPoint && (
            <>
              <Form.Item label="Produkti:">
                <Form.List name="products">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, i) => (
                        <Space
                          key={field.key}
                          style={{ display: 'flex', marginBottom: 8 }}
                          align="baseline"
                        >
                          <Input.Group compact size="small">
                            <Form.Item
                              {...field}
                              name={[field.name, 'product']}
                              fieldKey={[field.fieldKey, 'product']}
                              hasFeedback
                              label="Produkts"
                              validateStatus={productStatus}
                              rules={[
                                { required: true, message: 'Obligāts lauks!' },
                              ]}
                              help={
                                productStatus === 'error' &&
                                'Nedrīkst pievienot produktu duplikātus!'
                              }
                            >
                              <Select placeholder="Izvēlies produktu">
                                {products &&
                                  products?.map((product) => {
                                    return (
                                      <Option value={product.id} key={uuidv4()}>
                                        {product.name}
                                      </Option>
                                    )
                                  })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'boxes_taken']}
                              label="Paņ. kastes"
                              fieldKey={[field.fieldKey, 'boxes_taken']}
                              rules={[
                                {
                                  type: 'number',
                                  min: 0,
                                  max: 1000,
                                  required: true,
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="kastes"
                                type="number"
                                pattern="[0-9]*"
                                min={0}
                                step={1}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'quantity']}
                              label="Svars nosauktais"
                              fieldKey={[field.fieldKey, 'quantity']}
                              rules={[
                                {
                                  required: true,
                                  type: 'number',
                                  min: 0,
                                  max: 10000,
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="kg"
                                type="number"
                                pattern="[0-9]*"
                                // inputmode="numeric"
                                min={0}
                                step={0.1}
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'sum']}
                              label="Summa"
                              fieldKey={[field.fieldKey, 'sum']}
                              rules={[
                                { required: true, message: 'Obligāts lauks!' },
                              ]}
                            >
                              <InputNumber
                                placeholder="EUR"
                                type="number"
                                pattern="[0-9]*"
                              />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, 'quantity_driver']}
                              label="Pieņ. svars"
                              fieldKey={[field.fieldKey, 'quantity_driver']}
                              rules={[
                                {
                                  type: 'number',
                                  min: 0,
                                  max: 10000,
                                },
                              ]}
                            >
                              <InputNumber
                                placeholder="kg"
                                type="number"
                                pattern="[0-9]*"
                                min={0}
                                step={0.1}
                              />
                            </Form.Item>
                            {avgPrice && avgPrice[i]?.resultAvgPrice && (
                              <Form.Item label="Vid. cena">
                                <InputNumber
                                  placeholder="?"
                                  value={`${avgPrice[i].resultAvgPrice}EUR`}
                                  disabled
                                />
                              </Form.Item>
                            )}
                            {avgPrice &&
                              avgPrice[i]?.resultWeightDiffKg.amount && (
                                <Form.Item label="∆ (kg)">
                                  <InputNumber
                                    placeholder="?"
                                    // value={`${avgPrice[i].resultWeightDiffKg.amount}kg`}
                                    value={`${
                                      avgPrice[i].resultWeightDiffKg
                                        .type === 'deficit'
                                        ? '-'
                                        : '+'
                                    }${
                                      avgPrice[i].resultWeightDiffKg.amount
                                    }%`}
                                    disabled
                                  />
                                </Form.Item>
                              )}
                            {avgPrice &&
                              avgPrice[i]?.resultWeightDiffPercentage && (
                                <Form.Item label="∆ (%)">
                                  <InputNumber
                                    placeholder="?"
                                    value={`${
                                      avgPrice[i].resultWeightDiffPercentage
                                        .type === 'deficit'
                                        ? '-'
                                        : '+'
                                    }${
                                      avgPrice[i].resultWeightDiffPercentage
                                        .amount
                                    }%`}
                                    disabled
                                  />
                                </Form.Item>
                              )}
                            <Form.Item label="Dzēst">
                              <MinusCircleOutlined
                                onClick={() => remove(field.name)}
                              />
                            </Form.Item>
                          </Input.Group>
                        </Space>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                        >
                          Produkts
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
              <Tag>Izmaksājamā summa: {avgPrice && sumToBePaid()} EUR</Tag>
              <Tag>
                Kopā atstātās kastes: {avgPrice && totalBoxesLeft()} gb.
              </Tag>

              <Row gutter="30">
                <Col>
                  <Form.Item
                    label="Izsniegtās kastes:"
                    name="boxes_left"
                    rules={[{ type: 'number', min: 0, max: 500 }]}
                  >
                    <InputNumber
                      type="number"
                      pattern="[0-9]*"
                      min={0}
                      step={1}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="Izmaksātā summa:"
                    name="paid_out_funds"
                    rules={[{ type: 'number', min: 0, max: 100000 }]}
                  >
                    <InputNumber
                      placeholder=""
                      type="number"
                      pattern="[0-9]*"
                      min={0}
                      step={1}
                    />
                  </Form.Item>
                </Col>
                <Form.Item
                  label="Kases samazinājums punktā:"
                  name="returned_funds"
                  rules={[{ type: 'number', min: 0, max: 10000 }]}
                >
                  <InputNumber
                    placeholder=""
                    type="number"
                    pattern="[0-9]*"
                    min={0}
                    step={1}
                  />
                </Form.Item>
                <Col>
                  <Form.Item
                    label="Bonus:"
                    name="paid_salary"
                    rules={[{ type: 'number', min: 0, max: 10000 }]}
                  >
                    <InputNumber
                      placeholder=""
                      type="number"
                      pattern="[0-9]*"
                      min={0}
                      step={1}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item name="comment_switch" label="Komentārs:">
                <Switch checked={commentStatus} onChange={onCommentSwitch} />
              </Form.Item>
              {commentStatus && (
                <Form.Item name="comment_content">
                  <TextArea
                    placeholder="komentāra saturs..."
                    autoSize={{ minRows: 2, maxRows: 10 }}
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!canProceed ? true : false}
                >
                  Tālāk
                </Button>
              </Form.Item>
            </>
          )}
        </Form>

        {showPreview() && (
          <SubmissionPreview
            data={submissionData}
            setCurrentStep={setCurrentStep}
            setResultType={setResultType}
            setPreviewSubmitResult={setPreviewSubmitResult}
          />
        )}

        {currentStep === 3 && resultType && (
          <SubmissionResult type={resultType} />
        )}
      </Space>
    </>
  )
}
