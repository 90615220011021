import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Typography, Space, Button } from 'antd'
import { useRxData } from 'rxdb-hooks'
import { getPointsLocal, getProductsLocal } from '../db/db'
import { AveragePrice } from './AveragePrice'
import {
  percentageDiff,
  weightDiff,
  validNumberFormatter,
} from '../../utils/utils'
import {
  InboxOutlined,
  EuroCircleOutlined,
  CommentOutlined,
} from '@ant-design/icons'
import { ProductCard } from './ProductCard'
import { useRxCollection } from 'rxdb-hooks'
import { customAlphabet } from 'nanoid'
import { useStore } from '../../state/store'
const { DateTime } = require('luxon')

const { Title } = Typography

export const SubmissionPreview = (props) => {
  const [pointName, setPointName] = useState(null)
  const [products, setProducts] = useState(null)
  const { setPreviewSubmitResult } = props
  const points = useStore((state) => state.points)

  const handleGoBack = () => {
    props.setCurrentStep(0)
  }

  // const handlePreviewSubmit = async () => {
  //   props.setCurrentStep(3)
  //   await insertPurchase()
  //   props.setResultType({
  //     type: 'success',
  //     message: 'Produkta iepirkums veiksmīgi iesniegts sinhronizēšanai!',
  //   })
  // }

  const collection = useRxCollection('submissions')

  const insertPurchase = async () => {
    const nanoid = customAlphabet('0123456789abcdefghijklmnopqrstuvwxyz', 15)
    let id = nanoid()

    let user = localStorage.getItem('user')
    let userDetails = JSON.parse(user)

    const myDateTime = DateTime.now()
    const myDateTimeISO = myDateTime.toISODate()

    let productsArray = []

    if (props?.data?.products?.length > 0) {
      for (const item of props.data.products) {
        const productObject = {
          type: validNumberFormatter(item.product),
          boxesTaken: validNumberFormatter(item.boxes_taken),
          quantity: validNumberFormatter(item.quantity),
          quantityDriver: validNumberFormatter(item.quantity_driver),
          sum: validNumberFormatter(item.sum),
        }
        productsArray.push(productObject)
      }
    }

    collection.insert({
      id: id,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      point: validNumberFormatter(props.data.point),
      driver: validNumberFormatter(userDetails.uid),
      boxesLeft: validNumberFormatter(props.data.boxes_left),
      paidOutFunds: validNumberFormatter(props.data.paid_out_funds),
      paidSalary: validNumberFormatter(props.data.paid_salary),
      returnedFunds: validNumberFormatter(props.data.returned_funds),
      date: myDateTimeISO,
      comment: props.data.comment_content,
      syncStatus: 'unsynced',
      products: productsArray,
    })
  }

  const handlePublish = async () => {
    await insertPurchase()
    setPreviewSubmitResult()
  }

  useEffect(() => {
    const getPointName = async (props) => {
      if (props?.data?.point) {
        const foundPoint = points.find((point) => point.id == props.data.point)
        setPointName(foundPoint.name)
      }
    }

    getPointName(props)
    props?.data?.products && setProducts(props.data.products)
  }, [props])

  return (
    <Space size="middle" direction="vertical" style={{ width: '100%' }}>
      <Title level={4}>Kopsavilkums</Title>
      <Card title={<strong>{pointName}</strong>}>
        <div>
          <span>
            <strong>
              <InboxOutlined /> Atstātās kastes:{' '}
            </strong>
            {props.data?.boxes_left}
          </span>
        </div>
        <div>
          <span>
            <strong>
              <EuroCircleOutlined /> Izmaksātā summa:{' '}
            </strong>
            {props.data?.paid_out_funds} EUR
          </span>
        </div>
        <div>
          <span>
            <strong>
              <EuroCircleOutlined /> Izmaksātā alga:{' '}
            </strong>
            {props.data?.paid_salary} EUR
          </span>
        </div>
        <div>
          <span>
            <strong>
              <EuroCircleOutlined /> Avansa atmaksa:{' '}
            </strong>
            {props.data?.returned_funds} EUR
          </span>
        </div>
        {props.data?.comment_switch && props.data?.comment_content && (
          <div>
            <span>
              <strong>
                <CommentOutlined /> Komentārs:{' '}
              </strong>
              {props.data?.comment_content}
            </span>
          </div>
        )}
      </Card>
      <Title level={5}>Produkti</Title>
      <Row gutter={10}>
        {products &&
          products.map((item) => (
            <Col xl={{ span: 4, offset: 0 }}>
              <ProductCard item={item} />
            </Col>
          ))}
      </Row>
      <Space>
        <Button onClick={(e) => handleGoBack()}>Labot</Button>
        <Button onClick={(e) => handlePublish()} type="primary">
          Iesniegt
        </Button>
      </Space>
    </Space>
  )
}

export default SubmissionPreview
