import { roundNumber, validNumberFormatter } from '../../utils/utils'

export const AveragePrice = (props) => {
  const { sum, weight } = props
  if (sum <= 0) return '?'

  let avgPrice = sum / weight
  return !isNaN(avgPrice)
    ? roundNumber(avgPrice)
    : validNumberFormatter(avgPrice)
}

export default AveragePrice
