import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Typography, Space, Button } from 'antd'
import { getPointsLocal, getProductsLocal } from '../db/db'
import { AveragePrice } from './AveragePrice'
import { percentageDiff, weightDiff } from '../../utils/utils'
import { InboxOutlined, EuroCircleOutlined } from '@ant-design/icons'
import useStore from '../../state/store'

export const ProductCard = (props) => {
  const { item } = props
  const products = useStore((state) => state.products)

  const getProductBgImage = (product) => {
    let image = ''
    switch (product) {
      case '13':
        image = 'blueberries_headline.jpg'
        break
      case '14':
        image = 'lingonberries_headline.jpg'
        break
      case '15':
        image = 'chanterelles_headline.jpeg'
        break
      default:
        image = 'forest_headline.jpg'
        break
    }

    return {
      'background-image': `url("/images/headlines/${image}")`,
      'background-size': 'cover',
      'background-position': 'center',
      color: 'white',
      'text-shadow': '1px 1px black',
      'min-height': '50px',
      display: 'flex',
      'flex-direction': 'column',
      'justify-content': 'center',
    }
  }

  const productId = item.product ? item.product : item.type

  return (
    <Card
      size="small"
      title={
        <strong>{products.find((item) => item.id == productId).name}</strong>
      }
      headStyle={getProductBgImage(productId)}
    >
      <div>
        <strong>Paņ. kastes: </strong>
        <span>{item.boxes_taken} gb.</span>
      </div>
      <div>
        <strong>Svars: </strong>
        <span>{item.quantity} kg</span>
      </div>
      <div>
        <strong>Pārsv. svars: </strong>
        <span>{item.quantity_driver} kg</span>
      </div>
      {weightDiff(item.quantity, item.quantity_driver) && (
        <div>
          <strong>∆ (kg): </strong>
          <span
            dangerouslySetInnerHTML={{
              __html: weightDiff(item.quantity, item.quantity_driver).formatted,
            }}
          />
        </div>
      )}
      <div>
        <strong>∆ (%): </strong>
        <span
          dangerouslySetInnerHTML={{
            __html: percentageDiff(item.quantity, item.quantity_driver)
              .formatted,
          }}
        />
      </div>
      <div>
        <strong>Summa: </strong>
        <span>{item.sum} EUR</span>
      </div>
      <div>
        <strong>Vidējā cena: </strong>
        <span>
          <AveragePrice sum={item.sum} weight={item.quantity} /> EUR
        </span>
      </div>
    </Card>
  )
}
