import React from 'react'
import { Avatar } from 'antd'

export const ProductSymbol = (props) => {
  const { product } = props
  return null
  const shortName = (name) => {
    return `${name[0].toUpperCase()}${name[1].toUpperCase()}`
  }

  const productColor = (name) => {
    switch (name.toLowerCase()) {
      case 'mellenes':
        return { backgroundColor: '#fde3cf', color: '#f56a00' }
      case 'brūklenes':
        return { backgroundColor: '#fde3cf', color: '#29b5e8' }
      default:
        return { backgroundColor: '#fde3cf', color: '#f56a00' }
    }
  }

  if (product?.product?.length < 2) return
  return (
    <Avatar size="small" style={productColor(product?.product)}>
      {shortName(product?.product)}
    </Avatar>
  )
}
