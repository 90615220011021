import React from 'react'
import { Card, Row, Col, List, Avatar } from 'antd'
import { Link } from 'react-router-dom'
import {
  HomeOutlined,
  ShoppingCartOutlined,
  HistoryOutlined,
  CalendarOutlined,
  CarOutlined,
  RocketOutlined,
  SyncOutlined,
  PercentageOutlined,
  EuroCircleOutlined,
  InsertRowAboveOutlined,
  SettingOutlined,
} from '@ant-design/icons'
const { Meta } = Card

export const LandingPage = () => {
  const data = [
    {
      title: 'Jauns iepirkums',
      path: '/purchase',
      icon: <ShoppingCartOutlined />,
      description: 'Iesniegt jaunu iepirkumu',
    },
    {
      title: 'Visi iepirkumu',
      path: '/submissions',
      icon: <InsertRowAboveOutlined />,
      description: 'Satur visus iepirkumus',
    },
    {
      title: 'Punktu pārskats',
      path: '/points',
      icon: <HistoryOutlined />,
      description: 'Konkrāta punkta iepirkumi, statistika',
    },
    {
      title: 'Dienu pārskats',
      path: '/daily',
      icon: <CalendarOutlined />,
      description: 'Datu atlase par konkrētu dienu',
    },
    // {
    //   title: 'Svēršanu pārskats',
    //   path: '/weighting',
    //   icon: <PercentageOutlined />,
    //   description: 'Visas produkcijas svēršanas ieraksti',
    // },
    {
      title: 'Noliktavas nauda',
      path: '/transfers',
      icon: <EuroCircleOutlined />,
      description: 'Naudas apmaiņa starp šoferi un noliktavu',
    },
    {
      title: 'Degviela',
      path: '/fuel',
      icon: <CarOutlined />,
      description: 'Degvielas pārskati, to iensiegšana',
    },
    {
      title: 'Iestatījumi',
      path: '/settings',
      icon: <SettingOutlined />,
      description: 'Lietotnes iestatījumi',
    },
  ]

  return (
    <List
      itemLayout="horizontal"
      dataSource={data}
      // size="large"
      grid={{
        column: 2,
        xl: 4,
        xxl: 4,
      }}
      renderItem={(item) => (
        <List.Item>
          <Link to={item.path}>
            <List.Item.Meta
              avatar={item.icon}
              title={<Link to={item.path}>{item.title}</Link>}
              description={item.description}
            />
          </Link>
        </List.Item>
      )}
    />
  )
}
