import React, { useState, useEffect } from 'react'
import axios from 'axios'
import useStore from './../../state/store'
import { allPointFundsBefore } from '../db/dbQueries'
import { ProductWeight } from './ProductWeight'
import { Statistic, Row, Col, Popover, Button, Spin } from 'antd'
import {
  ShoppingOutlined,
  EuroCircleOutlined,
  BarChartOutlined,
} from '@ant-design/icons'
import {
  dailyReceivedFunds,
  dailyGivenBackdFunds,
  driverFunds,
  dailyTransfers,
  dailyPurchaseWeighting,
  dailyProductsStatistics,
  pointSalaries,
} from '../db/dbQueries'
import { DateTime } from 'luxon'

export const HeaderStatistics = () => {
  const jwt = localStorage.getItem('jwt_berry')
  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)

  const now = DateTime.now()
  const date = now.toISODate()

  const subs = useStore((state) => state.submissions)
  const products = useStore((state) => state.products)
  const transfers = useStore((state) => state.transfers)
  const transport = useStore((state) => state.transport)
  const fuel = useStore((state) => state.fuel)
  const driverTransfersReceived = transfers.filter(
    (transfer) => transfer.type === 'send',
  )
  const driverTransfersSent = transfers.filter(
    (transfer) => transfer.type === 'take',
  )

  if (products?.length === 0) return <Spin size="small" />

  const subsToday = subs.filter((sub) => sub.date === date)
  const productsAll = subs.map((sub) => sub.products).flat()
  const productsToday = subsToday.map((sub) => sub.products).flat()

  const reducerSum = (prev, current) => {
    return prev + current.sum
  }

  const reducerWeight = (prev, current) => prev + current.quantity
  const reducerboxesTaken = (prev, current) => prev + current.boxesTaken
  const reducerPaidOutFunds = (prev, current) => prev + current.paidOutFunds
  const reducerTransferred = (prev, current) => prev + current.amount

  const totalPaidOutFunds = subs.reduce(reducerPaidOutFunds, 0)
  const totalTransfersReceived = driverTransfersReceived.reduce(
    reducerTransferred,
    0,
  )
  const totalTransfersSent = driverTransfersSent.reduce(reducerTransferred, 0)

  const totalProductsSum = productsAll.reduce(reducerSum, 0)
  const totalProductsSumToday = productsToday.reduce(reducerSum, 0)
  const totalProductsWeight = productsToday.reduce(reducerWeight, 0)
  const totalBoxesTaken = productsToday.reduce(reducerboxesTaken, 0)
  const calculateProductSum = (type) =>
    productsToday
      .filter((product) => product.type == type)
      .reduce(reducerSum, 0)
  const calculateProductWeight = (type) =>
    productsToday
      .filter((product) => product.type == type)
      .reduce(reducerWeight, 0)

  let productsStats = []

  // /=(B33-G38)+B34
  // let fundsAtDriverNow =
  //   totalTransfersReceived - totalTransfersSent - totalProductsSum
  // fundsAtDriverNow = Math.round((fundsAtDriverNow + Number.EPSILON) * 100) / 100
  // productsToday.forEach((product) => {
  //   const found = productsStats.some((item) => item.type == product.type)
  //   if (!found) {
  //     productsStats.push({
  //       type: product.type,
  //       name: products.find((item) => product.type == item.id).name,
  //       weight: calculateProductWeight(product.type),
  //       sum: calculateProductSum(product.type),
  //     })
  //   }
  // })

  const endOfTheDay = DateTime.fromISO(date, { zone: 'Europe/Riga' }).endOf(
    'day',
  )

  let fundsAtDriverNow = driverFunds(
    subs,
    transfers,
    endOfTheDay.toSeconds(),
    'before',
  )

  const hasBeenWeighted = false

  const StatisticsPopupContent = () => {
    if (hasBeenWeighted) {
      return 'Prece šodien jau ir tikusi svērta, tātad nodota.'
    } else {
      if (true) {
        return (
          <Col>
            <ProductWeight
              products={productsStats}
              weightSum={totalProductsWeight}
              totalSum={totalProductsSumToday}
              boxesTaken={totalBoxesTaken}
              fundsAtDriverNow={fundsAtDriverNow}
            />
          </Col>
        )
      }
    }
  }

  return (
    <>
      <Row justify="end" align="middle" gutter="10">
        <Popover
          content={StatisticsPopupContent()}
          title="Statistika"
          trigger="click"
          // visible={statisticsVisiblity}
          onVisibleChange={console.log('changed')}
        >
          <Button icon={<BarChartOutlined />} disabled={hasBeenWeighted}>
            Statistika
          </Button>
        </Popover>
        {/* {!hasBeenWeighted && (
          <>
            <Col>
              <Statistic
                value={`${totalProductsWeight} kg`}
                prefix={<ShoppingOutlined />}
                valueStyle={{ color: 'white' }}
              />
            </Col>
            <Col>
              <Statistic
                value={`${allPointFundsBefore(subs, Date.now())} EUR`}
                prefix={<EuroCircleOutlined />}
                valueStyle={{ color: 'white' }}
              />
            </Col>
          </>
        )} */}
        {/* <Col>
          <Statistic
            value={`${fundsAtDriverNow} EUR`}
            prefix={<EuroCircleOutlined />}
            valueStyle={{ color: 'white' }}
          />
        </Col> */}
      </Row>
    </>
  )
}

export default HeaderStatistics
