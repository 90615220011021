export const pointsSchema = {
  title: 'Points schema',
  description: 'Describes points',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    createdAt: {
      type: 'number',
    },
    updatedAt: {
      type: 'number',
    },
    name: {
      type: 'string',
      minimum: 0,
    },
    drivers: {
      type: 'array',
      items: {
        type: 'number',
      },
    },
  },
  required: ['id', 'name'],
}
