import React from 'react'
import { Card, Col, Empty, Row } from 'antd'
import { DateTime } from 'luxon'
import useStore from '../../state/store'
import {
  dailyReceivedFunds,
  dailyGivenBackdFunds,
  driverFunds,
  dailyTransfers,
  dailyPurchaseWeighting,
  dailyProductsStatistics,
  pointSalaries,
} from '../db/dbQueries'
import { WeightingRecordTable } from '../warehouse/WeightingRecordTable'

export const DailyStatsContainer = (props) => {
  const { dailySubs, submissions, transfers, weightings, date } = props
  console.log('daily111', dailySubs)
  const startOfTheDay = DateTime.fromISO(date, { zone: 'Europe/Riga' }).startOf(
    'day',
  )
  const endOfTheDay = DateTime.fromISO(date, { zone: 'Europe/Riga' }).endOf(
    'day',
  )

  const products = useStore((state) => state.products)

  const fundsBefore = () => {
    return driverFunds(
      submissions,
      transfers,
      startOfTheDay.toSeconds(),
      'before',
    )
  }

  const fundsAfter = () => {
    return driverFunds(submissions, transfers, endOfTheDay.toSeconds(), 'after')
  }

  const hasBeenWarehouseWeighted = () => {
    if (dailyPurchaseWeighting(weightings, date)?.products) return true
    return false
  }

  const weightingTitle = () => {
    if (hasBeenWarehouseWeighted()) return 'Produkcija (svērta)'
    return 'Produkcija (nav svērta)'
  }

  const dailyPurchaseData = () => {
    if (dailyProductsStatistics(dailySubs))
      return dailyProductsStatistics(dailySubs)
    return null
  }

  const totalDailySum = () => {
    const dailyStats = dailyPurchaseData()
    if (dailyStats) {
      return dailyStats.reduce((prev, next) => prev + next.sum, 0)
    }
    return null
  }

  const weightingData = () => {
    if (dailyPurchaseWeighting(weightings, date))
      return dailyPurchaseWeighting(weightings, date).products
    if (dailyProductsStatistics(dailySubs))
      return dailyProductsStatistics(dailySubs)
    return null
  }

  const displayWeighting = () => {
    if (!weightingData()) return <Empty />

    return (
      <WeightingRecordTable
        weightedProducts={weightingData()}
        products={products}
        submissions={dailySubs}
        hasBeenWarehouseWeighted={hasBeenWarehouseWeighted()}
        dailyPurchaseData={dailyPurchaseData()}
      />
    )
  }

  return (
    <div className="site-card-wrapper">
      <Row gutter={16}>
        <Col sm={24} md={12}>
          <Card title="Naudas izmaiņas" bordered={true}>
            <span>
              <strong>
                Nauda pie šofera dienas sākumā: {fundsBefore()} EUR
              </strong>
            </span>
            <br />
            <span>
              Dienas laikā saņemtā nauda:{' '}
              {dailyReceivedFunds(dailyTransfers(transfers, date))}
            </span>
            <br />
            <span>
              Šoferis atgriež avansu noliktavai:{' '}
              {dailyGivenBackdFunds(dailyTransfers(transfers, date))}
            </span>
            <br />
            <span>Algas punktiem: {pointSalaries(dailySubs)} EUR</span>
            <br />
            <span>Nauda produkcijā: {totalDailySum()} EUR</span>
            <br />
            <span>
              <strong>
                Naudas pie šofera dienas beigās: {fundsAfter()} EUR
              </strong>
            </span>
            <br />
          </Card>
        </Col>
        <Col sm={24} md={12}>
          <Card title={weightingTitle()} bordered={true}>
            {displayWeighting()}
          </Card>
        </Col>
        {/* <Col span={8}>
          <Card title="Card title" bordered={true}>
            Card content
          </Card>
        </Col> */}
      </Row>
    </div>
  )
}

export default DailyStatsContainer
