import create from 'zustand'

const getProductDisplayStyle = () => {
  const setting = localStorage.getItem('berry_product_display')
  if (setting && setting === 'true') return true
  return false
}

const handleProductDisplayChange = (checked) => {
  localStorage.setItem('berry_product_display', JSON.stringify(checked))
  // setProductStyle(checked)
}

export const useStore = create((set) => ({
  submissions: null,
  removeSubmissions: () => set({ submissions: null }),
  products: null,
  points: null,
  transfers: null,
  weighting: null,
  transport: null,
  fuel: null,

  productStyle: getProductDisplayStyle(),
  setProductStyle: (style) => {
    set({ productStyle: style })
    handleProductDisplayChange(style)
  },
}))

export default useStore
