import React, { useContext } from 'react'
// import useStore from 'zustand'
import { useStore } from '../../utils/state'
import { Form, Input, Button, message } from 'antd'
import axios from 'axios'
import { useHistory } from 'react-router'
import auth from '../../utils/auth'
import { AuthContext } from '../../utils/auth-context'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { Route, Redirect } from 'react-router-dom'

import { useMutation } from 'react-query'

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
}
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
}

const Login = () => {
  const authenticateUser = useStore((state) => state.setLoggedIn)
  const loggedInStatus = useStore((state) => state.loggedIn)
  let history = useHistory()
  const redirectHome = () => {
    history.push('/')
  }
  const context = useContext(AuthContext)
  const handleAuthenticateUser = (type) => {
    authenticateUser(type)
  }

  const mutation = useMutation(
    (logindata) =>
      axios.post(
        `${process.env.REACT_APP_BACKEND_URL}user/login?_format=json`,
        logindata,
      ),
    {
      onSuccess: (response) => {
        let authorizedUser = response.data.current_user
        let jwtToken = response.data.access_token
        let logoutToken = response.data.logout_token
        localStorage.setItem('user', JSON.stringify(authorizedUser))
        localStorage.setItem('jwt_berry', jwtToken)
        localStorage.setItem('logout_token', logoutToken)

        auth.login(() => {
          redirectHome()
        })

        context.setLoading()
        context.setLoggedIn(true)
        handleAuthenticateUser()
      },
      onError: (error, variables, context) => {
        message.error('Kļūda autorizējoties!:' + error.response.data.message)
      },
    },
  )

  const onFinish = (values) => {
    mutation.mutate({
      name: values.username,
      pass: values.password,
    })
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }

  if (auth.isAuthenticated()) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    )
  }

  return (
    <Form
      {...layout}
      name="basic"
      initialValues={{
        remember: true,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Lietotājs"
        name="username"
        rules={[
          {
            required: true,
            message: 'Lūdzu ievadi lietotājvārdu!',
          },
        ]}
      >
        <Input prefix={<UserOutlined className="site-form-item-icon" />} />
      </Form.Item>

      <Form.Item
        label="Parole"
        name="password"
        rules={[
          {
            required: true,
            message: 'Lūdzu ievadi paroli!',
          },
        ]}
      >
        <Input.Password
          prefix={<LockOutlined className="site-form-item-icon" />}
        />
      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit">
          Ielogoties
        </Button>
      </Form.Item>
    </Form>
  )
}

export default Login
