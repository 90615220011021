import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { onServiceWorkerUpdate } from '@3m1/service-worker-updater'
import reportWebVitals from './reportWebVitals'
import UpdateNotification from './components/updates/UpdateNotification'

import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import lvLV from 'antd/lib/locale/lv_LV'
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { persistWithIndexedDB } from './utils/persistWithIndexedDB'
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 7 days
      refetchOnWindowFocus: true,
    },
  },
})

persistWithIndexedDB(queryClient)

// const localStoragePersistor = createWebStoragePersistor({ storage: window.localStorage })

// persistQueryClient({
//   queryClient,
//   persistor: localStoragePersistor,
// })

ConfigProvider.config({
  prefixCls: 'custom',
  theme: {
    primaryColor: '#25b864',
    // backgroundColor: '#25b864',
    // primaryColorOutline: '#25b864',
    // headingColor: '#0b6b3b',
  },
})

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ConfigProvider locale={lvLV} prefixCls="custom">
        <BrowserRouter>
          <UpdateNotification />
          <App />
        </BrowserRouter>
      </ConfigProvider>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    // Your code goes here
    // ...
    // Then, call this callback:
    onServiceWorkerUpdate(registration)
  },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
