import axios from 'axios'
import { message } from 'antd'
import { customAlphabet } from 'nanoid'
import { setSubmission, changeSubmissionStatus } from '../components/db/db'

let jwt = localStorage.getItem('jwt_berry')

export const fetchProducts = async () => {
  let jwt = localStorage.getItem('jwt_berry')

  const result = await axios(
    `${process.env.REACT_APP_BACKEND_URL}products-categories-rest-api?_format=json&XDEBUG_TRIGGER=yes`,
    { headers: { Authorization: `Bearer ${jwt}` } },
  )

  return result
}

export const fetchFuelRecords = async () => {
  const jwt = localStorage.getItem('jwt_berry')
  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)

  const result = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}fuel-usage-rest?_format=json`,
    { headers: { Authorization: `Bearer ${jwt}` } },
  )

  return result
}

export const publishFuelRecord = (data) => {
  axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}fuel/insert_record?_format=json&XDEBUG_TRIGGER=yes`,
      {
        data,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
          // withCredentials: true,
          // Cookie: "XDEBUG_SESSION=XDEBUG_ECLIPSE;",
        },
      },
    )
    .then((res) => {
      message.success('Atskaite veiksmīgi iesniegta!')
    })
    .catch((error) => {
      message.error('Neizdevās. Mēģiniet vēlreiz.')
    })
}

export const fetchPurchases = async () => {
  const jwt = localStorage.getItem('jwt_berry')
  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)

  const result = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}driver-purchase-deliveries-rest?_format=json`,
    { headers: { Authorization: `Bearer ${jwt}` } },
  )

  return result
}

export const fetchDailyPurchaseWeighting = async () => {
  const jwt = localStorage.getItem('jwt_berry')
  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)

  const result = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}product-weighting-api-daily?_format=json&driverid=${userDetails.uid}`,
    { headers: { Authorization: `Bearer ${jwt}` } },
  )

  return result
}

export const fetchSyncPurchases = async () => {
  const jwt = localStorage.getItem('jwt_berry')

  const result = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}sync/sync_status?_format=json`,
    { headers: { Authorization: `Bearer ${jwt}` } },
  )

  console.log('result111', result.data.count)

  return result?.data?.count
}

export const fetchIsWeighted = async (data) => {
  const jwt = localStorage.getItem('jwt_berry')
  return axios
    .post(
      `${process.env.REACT_APP_BACKEND_URL}weighting/weighting_record_existance?_format=json&XDEBUG_TRIGGER=yes`,
      {
        data,
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'Content-Type': 'application/json',
          // withCredentials: true,
          // Cookie: "XDEBUG_SESSION=XDEBUG_ECLIPSE;",
        },
      },
    )
    .then((res) => {
      return res
      // message.success('Atskaite veiksmīgi iesniegta!', res.data);
    })
    .catch((error) => {
      console.log('error', error)
      // message.error('Neizdevās. Mēģiniet vēlreiz.')
    })
}

export const fetchDriverStats = async (uid) => {
  // try {
  const userData = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}driver-profile-rest?uid=${uid}&_format=json`,
    { headers: { Authorization: `Bearer ${jwt}` } },
  )
  return userData
  // } catch (e) {
  //   console.log('no funds')
  // }
}

export const fetchMoneyTransfers = async (uid) => {
  // try {
  const transferData = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}money-transfers-rest?driverid=${uid}&_format=json`,
    { headers: { Authorization: `Bearer ${jwt}` } },
  )
  return transferData
  // } catch (e) {
  //   console.log('no funds')
  // }
}
