import React from 'react'

// set the defaults
export const AuthContext = React.createContext({
  loading: true,
  setLoading: () => {},
  avatar_url:
    'https://www.shareicon.net/data/128x128/2017/01/06/868320_people_512x512.png',
  setAvatarUrl: () => {},
  username: null,
  setUsername: () => {},
  loggedIn: false,
  setLoggedIn: () => {},
})
