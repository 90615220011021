import React from 'react'
import { Typography, Table, Spin, Empty } from 'antd'
import { ProductSymbol } from '../submissions/ProductSymbol'
import { productsSumByType } from '../db/dbQueries'
import { percentageDiff, weightDiff } from '../../utils/utils'

export const WeightingRecordTable = (props) => {
  const {
    products,
    weightedProducts,
    submissions,
    hasBeenWarehouseWeighted,
    dailyPurchaseData,
  } = props

  console.log('dailyPurchaseData', dailyPurchaseData)

  const { Text } = Typography

  let columns = []

  columns.push({
    title: 'Produkts',
    dataIndex: 'type',
    key: 'type',
    render: (text, record, id) => (
      <>
        {console.log('999', record)}
        <ProductSymbol
          product={products.find((product) => product.id == record.type)?.name}
        />{' '}
        {products.find((product) => product.id == record.type)?.name}
      </>
    ),
  })

  columns.push({
    title: 'Iepirktā prod. (kg)',
    dataIndex: 'quantity',
    key: 'quantity',
    render: (text, record, id) => (
      <>
        {dailyPurchaseData.find((item) => item.type === record.type)?.quantity}
      </>
    ),
  })

  if (!hasBeenWarehouseWeighted) {
    columns.push({
      title: 'Pārsvērts (šof) (kg)',
      dataIndex: 'quantityDriver',
      key: 'quantityDriver',
      render: (text, record, id) => (
        <>
          {
            dailyPurchaseData.find((item) => item.type === record.type)
              ?.quantityDriver
          }
        </>
      ),
    })
  }

  columns.push({
    title: 'Iztērētā nauda (EUR)',
    dataIndex: 'sum',
    key: 'totalSum',
    render: (text, record, something) => {
      return <>{productsSumByType(submissions, record.type)}</>
    },
  })

  if (!hasBeenWarehouseWeighted) {
    columns.push({
      title: '∆ (kg)',
      dataIndex: 'diffKg',
      key: 'diffKg',
      render: (text, row, id) => (
        <div
          dangerouslySetInnerHTML={{
            __html: weightDiff(row.quantity, row.quantityDriver).formatted,
          }}
        />
      ),
    })

    columns.push({
      title: '∆ (%)',
      dataIndex: 'diffPercent',
      key: 'diffPercent',
      render: (text, row, id) => (
        <div
          dangerouslySetInnerHTML={{
            __html: percentageDiff(row.quantity, row.quantityDriver).formatted,
          }}
        />
      ),
    })
  }

  if (hasBeenWarehouseWeighted) {
    columns.push({
      title: 'Nosvērts bāzē (kg)',
      dataIndex: 'quantity',
      key: 'quantity',
    })
    columns.push({
      title: '∆ (kg)',
      dataIndex: 'diffKg',
      key: 'diffKg',
    })

    columns.push({
      title: '∆ (%)',
      dataIndex: 'diffPercent',
      key: 'diffPercent',
    })
  }

  return (
    <Table
      dataSource={weightedProducts}
      columns={columns}
      pagination={false}
      summary={(pageData) => {
        console.log('pageData', pageData)
        let totalQuantityPoint = 0
        let totalQuantityDriver = 0
        let totalSumForType = 0
        let totalQuantity = 0

        if (hasBeenWarehouseWeighted) {
          pageData.forEach(({ quantityPoint, quantity, type }) => {
            totalQuantityPoint += quantityPoint
            totalQuantity += quantity
            totalSumForType += productsSumByType(submissions, type)
          })
        } else {
          pageData.forEach(
            ({ quantityPoint, quantityDriver, quantity, type }) => {
              totalQuantityPoint += quantityPoint
              totalQuantityDriver += quantityDriver
              totalQuantity += quantity
              totalSumForType += productsSumByType(submissions, type)
            },
          )
        }

        return (
          <>
            {hasBeenWarehouseWeighted && (
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <Text strong>Kopā</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalQuantityPoint}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalSumForType}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalQuantity}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}

            {!hasBeenWarehouseWeighted && (
              <Table.Summary.Row>
                <Table.Summary.Cell>
                  <Text strong>Kopā</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalQuantity}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalQuantityDriver}</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell>
                  <Text strong>{totalSumForType}</Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
          </>
        )
      }}
    />
  )
}
