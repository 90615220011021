export const submissionsSchema = {
  title: 'Submissions schema',
  description: 'Describes submissions',
  version: 1,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    nid: {
      type: 'number',
    },
    syncStatus: {
      type: 'string',
    },
    createdAt: {
      // should be created. Also updatedAt field needs to be added.
      type: 'number',
    },
    updatedAt: {
      type: 'number',
    },
    point: {
      type: 'number',
      minimum: 0,
    },
    driver: {
      type: 'number',
      minimum: 0,
    },
    boxesLeft: {
      type: 'number',
      minimum: 0,
    },
    paidOutFunds: {
      type: 'number',
      minimum: 0,
    },
    paidSalary: {
      type: 'number',
      minimum: 0,
    },
    returnedFunds: {
      type: 'number',
      minimum: 0,
    },
    date: {
      type: 'string',
    },
    comment: {
      type: 'string',
    },
    products: {
      type: 'array',
      items: {
        type: 'object',
        properties: {
          type: {
            type: 'number',
            minimum: 0,
          },
          boxesTaken: {
            type: 'number',
            minimum: 0,
          },
          quantity: {
            type: 'number',
            minimum: 0,
          },
          quantityDriver: {
            type: 'number',
            minimum: 0,
          },
          sum: {
            type: 'number',
            minimum: 0,
          },
        },
      },
    },
  },
  required: ['id'],
}
