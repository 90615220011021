import React, { useContext } from 'react'
import auth from '../../utils/auth'
import { UserAvatar } from './UserAvatar'
import { Button, Tooltip, Layout, Row, Col, Popconfirm } from 'antd'
import { LogoutOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import { AuthContext } from '../../utils/auth-context'
import { HeaderStatistics } from './HeaderStatistics'
import { useStore } from './../../utils/state'
import { useRxDB } from 'rxdb-hooks'
const { Header } = Layout
// import { useStore } from '../../utils/state'

export const UserHeader = (props) => {
  const db = useRxDB()
  const { setLoggedIn } = useContext(AuthContext)
  // const authenticateUser = useStore((state) => state.setLoggedIn)
  // const loggedIn = useStore((state) => state.loggedIn)
  const state = useStore()
  const loggedInStatus = useStore((state) => state.loggedIn)

  const [visible, setVisible] = React.useState(false)
  const [confirmLoading, setConfirmLoading] = React.useState(false)

  const showPopconfirm = () => {
    setVisible(true)
  }

  const handleOk = async () => {
    setConfirmLoading(true)
    setVisible(false)
    await handleLogout()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  let history = useHistory()

  const handleLogout = async () => {
    // delete RxDb
    await db.remove()

    // delete old DexieJs db if there is one
    var req = indexedDB.deleteDatabase('BerryDatabase')
    req.onsuccess = function () {
      console.log('Deleted database successfully')
    }
    req.onerror = function () {
      console.log("Couldn't delete database")
    }
    req.onblocked = function () {
      console.log("Couldn't delete database due to the operation being blocked")
    }

    auth.logout(() => {
      setLoggedIn(false)
      history.push('/login')
    })
  }

  return (
    <Header className="site-layout-background" style={{ padding: 0 }}>
      {auth.isAuthenticated() ? (
        <div className="header-inner-wrapper">
          <Row justify="end" align="middle" gutter={10}>
            <Col>
              <HeaderStatistics />
              {/* <p>{loggedIn}</p> */}
              {/* {console.log('loggedIn', loggedIn)} */}
            </Col>
            <Col>
              <UserAvatar />
            </Col>
            <Col>
              <Popconfirm
                title="Vai tiešām vēlaties izlogoties? Tas ieteicams tikai, ja Jums pašlaik pieejams stabils interneta pārklājums. Neiesniegtie ieraksti tiks dzēsti."
                visible={visible}
                onConfirm={handleOk}
                okButtonProps={{ loading: confirmLoading }}
                onCancel={handleCancel}
                okText={'Jā'}
              >
                <Tooltip title="Izlogoties">
                  <Button
                    shape="circle"
                    icon={<LogoutOutlined />}
                    onClick={() => showPopconfirm()}
                  />
                </Tooltip>
              </Popconfirm>
            </Col>
          </Row>
        </div>
      ) : (
        <p>not logged in</p>
      )}
    </Header>
  )
}
