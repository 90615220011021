export const transfersSchema = {
  title: 'Transfers schema',
  description: 'Describes funds transfers between driver & warehouse',
  version: 0,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    createdAt: {
      type: 'number',
    },
    updatedAt: {
      type: 'number',
    },
    date: {
      type: 'string',
    },
    amount: {
      type: 'number',
    },
    type: {
      type: 'string',
    },
    giver: {
      type: 'string',
    },
    comment: {
      type: 'string',
    },
  },
  required: ['id'],
}
