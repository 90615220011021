import React, { useState, useEffect } from 'react'
import { Typography, Table } from 'antd'
import { Link } from 'react-router-dom'
import { Spin, Empty, Tag } from 'antd'
import { useQuery } from 'react-query'
import { useRxData } from 'rxdb-hooks'

const { Title } = Typography

export const TransferOverviewPage = (props) => {
  // const [Points, setPoints] = useState(null)
  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)

  const queryConstructorTransfers = (collection) => collection.find()

  const { result: transfers, isFetchingTransfers } = useRxData(
    'transfers',
    queryConstructorTransfers,
  )

  if (isFetchingTransfers) return <Spin />

  if (!transfers) return <Empty />

  // dataTransfers?.data?.forEach((item) => {
  //   transfers.push({
  //     date: item.created,
  //     type: item.field_transfer_type,
  //     amount: item.field_amount,
  //     giver: item.giver,
  //   })
  // })

  const columns = [
    {
      title: 'Datums',
      dataIndex: 'date',
    },
    {
      title: 'Veids',
      dataIndex: 'type',
      render: (text, row, index) => {
        if (text === 'send') return <Tag color="green">Saņemts</Tag>
        if (text === 'take') return <Tag color="volcano">Nodots</Tag>
      },
    },
    {
      title: 'EUR',
      dataIndex: 'amount',
      render: (text, row, index) => {
        if (row.type === 'send')
          return <span style={{ color: 'green' }}>+{text}</span>
        if (row.type === 'take')
          return <span style={{ color: 'red' }}>-{text}</span>
      },
    },
    {
      title: 'Izsniedzējs / Saņēmējs',
      dataIndex: 'giver',
    },
  ]

  return (
    <>
      <Title level={4}>Līdzekļu pārskats</Title>
      <Table
        dataSource={transfers}
        columns={columns}
        pagination={{ pageSize: 100 }}
      />
    </>
  )
}
