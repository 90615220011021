import React, { useState, useEffect } from 'react'
import { Result, Button, Spin, message } from 'antd'
import { Link } from 'react-router-dom'

export const SubmissionResult = (props) => {
  const successful = (message) => {
    return (
      <Result
        status="success"
        title="Pievienots!"
        subTitle={message}
        extra={[
          <Button type="primary" key="console">
            <Link to={'/submissions'}>Iepirkumu saraksts</Link>
          </Button>,
        ]}
      />
    )
  }

  const failed = (message) => {
    return (
      <Result
        status="warning"
        title="Kļūda iesniedzot!"
        subTitle={message}
        extra={[
          <Button type="primary" key="console">
            <Link to={'/submissions'}>Iepirkumu saraksts</Link>
          </Button>,
        ]}
      />
    )
  }
  // if (props?.type?.type === 'success') {
  return successful(props.type.message)
  // } else if (props?.type?.type === 'fail') {
  //   return failed(props.type.message)
  // } else {
  //   return <Spin size="large" />
  // }
}

export default SubmissionResult
