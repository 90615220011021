import React from 'react'
import auth from '../utils/auth'

export const Secret = (props) => {
  return (
    <div>
      <h1>App Layout</h1>
      <button
        onClick={() => {
          auth.logout(() => {
            props.history.push('/')
          })
        }}
      >
        Logout
      </button>
      <button
        onClick={() => {
          props.history.push('/')
        }}
      >
        Home
      </button>
    </div>
  )
}
