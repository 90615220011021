export const fuelSchema = {
  title: 'Fuel schema',
  description: 'Describes fuel schema',
  version: 1,
  primaryKey: 'id',
  type: 'object',
  properties: {
    id: {
      type: 'string',
    },
    nid: {
      type: 'number',
    },
    syncStatus: {
      type: 'string',
    },
    createdAt: {
      type: 'number',
    },
    updatedAt: {
      type: 'number',
    },
    date: {
      type: 'string',
    },
    liters: {
      type: 'number',
    },
    odometer: {
      type: 'number',
    },
    sum: {
      type: 'number',
    },
    receipt: {
      type: 'string',
    },
    transport: {
      type: 'number',
    },
  },
  required: ['id'],
}
