import React from 'react'
import { notification } from 'antd'
import { withServiceWorkerUpdater } from '@3m1/service-worker-updater'

const UpdateNotification = (props) => {
  const { newServiceWorkerDetected, onLoadNewServiceWorkerAccept } = props

  const handleUpdate = () => {
    onLoadNewServiceWorkerAccept()
  }

  const msg = {
    message: 'Pieejams atjauninājums!',
    description:
      'Pieejams jauns lietotnes atjauninājums. Lai ielādētu izmaiņas, spied uz šī paziņojuma.',
    onClick: () => handleUpdate(),
    duration: 0,
  }
  if (newServiceWorkerDetected) notification.info(msg)

  return <></>
}

export default withServiceWorkerUpdater(UpdateNotification)
