import React, { useState, useEffect } from 'react'
import { Descriptions, Row, Col, Table } from 'antd'
import { v4 as uuid } from 'uuid'
import { getSubmittedProducts, getProductsLocal } from '../db/db'
import { ProductSymbol } from './ProductSymbol'
import { AveragePrice } from './AveragePrice'
import { percentageDiff, weightDiff } from '../../utils/utils'
import useStore from '../../state/store'
import { ProductCard } from './ProductCard'

export const ExpandedRow = (props) => {
  const [orderedProducts, setOrderedProducts] = useState(props.products)
  const [productNames, setProductNames] = useState(null)
  const products = useStore((state) => state.products)
  useEffect(() => {
    const getProductNames = async () => {
      const products = await getProductsLocal()

      let productList = {}
      products.map((item, index) => {
        return (productList[parseInt(item.tid)] = item.product_name)
      })
      setProductNames(productList)
    }
    // getOrderedProducts()
    getProductNames()
    // setStyle(props.style)
  }, [orderedProducts])

  const descriptionStyle = (orderedProducts) => {
    const columns = [
      {
        title: 'Produkts',
        dataIndex: 'productName',
        key: 'productName',
        render: (text) => (
          <>
            <ProductSymbol product={text} /> {text}
          </>
        ),
      },
      {
        title: 'paņ. k.',
        dataIndex: 'boxesTaken',
        key: 'boxesTaken',
      },
      {
        title: 'svars (kg)',
        dataIndex: 'quantity',
        key: 'quantity',
      },
      {
        title: 'pārsvērts (kg)',
        dataIndex: 'quantityDriver',
        key: 'quantityDriver',
      },
      {
        title: '∆ (kg)',
        dataIndex: 'weightDiffKg',
        key: 'weightDiffKg',
        render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
      },
      {
        title: '∆ (%)',
        dataIndex: 'weightDiffPercentage',
        key: 'weightDiffPercentage',
        render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
      },
      {
        title: 'summa',
        dataIndex: 'sum',
        key: 'sum',
      },
      {
        title: 'vid. c.',
        dataIndex: 'avgPrice',
        key: 'avgPrice',
        render: (text) => (
          <>
            €<AveragePrice sum={text.sum} weight={text.quantity} />
          </>
        ),
      },
    ]

    let productArray = []
    if (Array.isArray(orderedProducts)) {
      orderedProducts.forEach((item, index) => {
        const productObject = {}
        productObject.key = item.type
        productObject.productName =
          item.type &&
          products?.length > 0 &&
          products.find((product) => product.id == item.type).name
        // productObject.aqqq = productNames[item.type]
        // productObject.productName = item.type
        productObject.boxesTaken = item.boxesTaken
        productObject.quantity = item.quantity
        productObject.quantityDriver = item.quantityDriver
        productObject.weightDiffKg = weightDiff(
          item.quantity,
          item.quantityDriver,
        ).formatted
        productObject.weightDiffPercentage = percentageDiff(
          item.quantity,
          item.quantityDriver,
        ).formatted
        productObject.sum = item.sum
        productObject.avgPrice = item
        productArray.push(productObject)
      })
    }

    return (
      <Table
        dataSource={productArray}
        columns={columns}
        size="small"
        pagination={false}
      />
    )
  }

  const cardStyle = (orderedProducts) => {
    return (
      <>
        <Row gutter={10}>
          {orderedProducts[0] &&
            orderedProducts.map((item, index) => {
              return (
                <Col xl={{ span: 4, offset: 0 }}>
                  <ProductCard item={item} />
                </Col>
              )
            })}
        </Row>
      </>
    )
  }

  const labelStyling = { fontWeight: '600' }

  if (props.style) {
    return cardStyle(orderedProducts)
  } else {
    return descriptionStyle(orderedProducts)
  }
}
