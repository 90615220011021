import { dehydrate, hydrate } from 'react-query/hydration'
import { get, set, del } from 'idb-keyval'
export async function persistWithIndexedDB(
  queryClient,
  {
    IndexedDBKey: indexedDBKey = `REACT_QUERY_OFFLINE_CACHE`,
    throttleTime = 1000,
    maxAge = 1000 * 60 * 60 * 24 * 40, // 7 days
    buster = (window === null || window === void 0
      ? void 0
      : window.App_Version) || '',
  } = {},
) {
  if (typeof window !== 'undefined') {
    // Subscribe to changes
    const saveCache = throttle(() => {
      const storageCache = {
        buster,
        timestamp: Date.now(),
        cacheState: dehydrate(queryClient),
      }
      set(indexedDBKey, JSON.stringify(storageCache)) // set in Indexed DB
    }, throttleTime)
    queryClient.getQueryCache().subscribe(saveCache)
    // Attempt restore
    const cacheStorage = await get(indexedDBKey) // get from Indexed DB
    if (!cacheStorage) {
      return
    }
    const cache = JSON.parse(cacheStorage)
    if (cache.timestamp) {
      const expired = Date.now() - cache.timestamp > maxAge
      const busted = cache.buster !== buster
      if (expired || busted) {
        del(indexedDBKey) // Delete from Indexed DB
      } else {
        hydrate(queryClient, cache.cacheState)
      }
    } else {
      del(indexedDBKey)
    }
  }
}
function throttle(func, wait = 100) {
  let timer = null
  return function (...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func(...args)
        timer = null
      }, wait)
    }
  }
}
