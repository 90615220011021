import React from 'react'
import { dailyProductsStatistics } from '../db/dbQueries'
import { WeightingRecordTable } from '../warehouse/WeightingRecordTable'
import useStore from '../../state/store'

export const PointStatisticsExtensive = (props) => {
  const { submissions } = props
  const stats = dailyProductsStatistics(submissions)
  const products = useStore((state) => state.products)

  return (
    <>
      <h2>Punkta statistika</h2>
      <WeightingRecordTable
        products={products}
        submissions={submissions}
        dailyPurchaseData={stats}
        weightedProducts={stats}
      />
    </>
  )
}

export default PointStatisticsExtensive
