import Dexie from 'dexie'
import { DateTime } from 'luxon'

export const db = new Dexie('BerryDatabase')
// Declare tables, IDs and indexes
db.version(26).stores({
  points:
    'tid, purchase_point, field_boxes_at_point, field_funds_at_point, point_driver',
  products: 'tid, product_name',
  submissions:
    'id, purchase_point, boxes_left, paid_out_funds, paid_salary, returned_funds, comment, point_funds_before, point_funds_after, point_boxes_before, point_boxes_after, driver_boxes_before, driver_boxes_after, status, date, timestamp, timestamp_changed',
  submissions_products:
    '++id, ref_submission, product_type, boxes_taken, quantity, sum, quantity_driver, diff_kg, diff_p',
  settings: '++id, name, contents',
})

db.open().catch((err) => {
  console.log(err.stack || err)
})

export const addPoint = (
  pointTid,
  pointName,
  pointBoxes,
  pointFunds,
  pointDriver,
) => {
  if (!pointTid || !pointName) return
  db.points.put({
    tid: pointTid,
    purchase_point: pointName,
    field_boxes_at_point: pointBoxes,
    field_funds_at_point: pointFunds,
    point_driver: pointDriver,
  })
}

// set points in the Dexie
export const setPointsLocal = (points) => {
  points.forEach((point) => {
    const pointTid = point.tid
    const pointName = point.name
    const pointBoxes = point.field_boxes_at_point_calculated
    const pointFunds = point.field_funds_at_point_calculated
    const pointDriver = point.field_point_driver
    addPoint(
      parseInt(pointTid),
      pointName,
      parseInt(pointBoxes),
      parseFloat(pointFunds, 10),
      pointDriver,
    )
  })
}

export const setSubmission = async (data) => {
  db.transaction('rw', db.submissions, db.submissions_products, async () => {
    const exist = await getSubmissions(data.id)
    if (exist[0]) return
    await db.submissions.put({
      id: data.id,
      purchase_point: data.point,
      boxes_left: data.boxes_left,
      paid_out_funds: data.paid_out_funds,
      paid_salary: data.paid_salary,
      returned_funds: data.returned_funds,
      comment: data.comment_content,
      point_funds_before: data.point_funds_before,
      point_funds_after: data.point_funds_after,
      point_boxes_before: data.point_boxes_before,
      point_boxes_after: data.point_boxes_after,
      driver_boxes_before: data.driver_boxes_before,
      driver_boxes_after: data.driver_boxes_after,
      status: data.status,
      timestamp: data.timestamp,
      timestamp_changed: data.timestamp_changed,
      date: data.date,
    })
    // await data.products.forEach(item => {
    //
    // 	db.submissions_products.put({ ref_submission: data.id, product_type: item.product, quantity: item.quantity, sum: item.sum, boxes_taken: item.boxes_taken })
    // });
    for (const item of data.products) {
      await db.submissions_products.put({
        ref_submission: data.id,
        product_type: item.product,
        quantity: item.quantity,
        quantity_driver: item.quantity_driver,
        sum: item.sum,
        boxes_taken: item.boxes_taken,
        diff_kg: item.diff_kg,
        diff_p: item.diff_p,
      })
    }
  }).catch((err) => {
    console.error('Failed to add submission to Dexie.js ' + err.stack)
  })
}

export const changeSubmissionStatus = (id, status) => {
  db.submissions.update(id, { status: status })
}

export const productSubmissionExists = async (id, type) => {
  const count = await db.submissions_products
    .where({ ref_submission: id, product_type: type })
    .count()

  if (count) return true
  return false
}

// check if modified timestamp differs
// check sub. status as it's change doesn't affect the Changed timestamp

export const changeSubmission = async (id, data) => {
  db.transaction('rw', db.submissions, db.submissions_products, async () => {
    const point_funds_before_normalized = data.point_funds_before || '?'
    const point_funds_after_normalized = data.point_funds_after || '?'
    const point_boxes_before_normalized = data.point_boxes_before || '?'
    const point_boxes_after_normalized = data.point_boxes_after || '?'
    const driver_boxes_before_normalized = data.driver_boxes_before || '?'
    const driver_boxes_after_normalized = data.driver_boxes_after || '?'
    db.submissions.update(id, {
      id: data.id,
      purchase_point: data.point,
      boxes_left: data.boxes_left,
      paid_out_funds: data.paid_out_funds,
      paid_salary: data.paid_salary,
      returned_funds: data.returned_funds,
      comment: data.comment_content,
      point_funds_before: point_funds_before_normalized,
      point_funds_after: point_funds_after_normalized,
      point_boxes_before: point_boxes_before_normalized,
      point_boxes_after: point_boxes_after_normalized,
      driver_boxes_before: driver_boxes_before_normalized,
      driver_boxes_after: driver_boxes_after_normalized,
      status: data.status,
      timestamp: data.timestamp,
      timestamp_changed: data.timestamp_changed,
      date: data.date,
    })
    for (const item of data.products) {
      const productCount = await productSubmissionExists(id, item.product)
      if (productCount) {
        await db.submissions_products
          .where({ ref_submission: id, product_type: item.product })
          .modify({
            ref_submission: data.id,
            product_type: item.product,
            quantity: item.quantity,
            quantity_driver: item.quantity_driver,
            sum: item.sum,
            boxes_taken: item.boxes_taken,
            diff_kg: item.diff_kg,
            diff_p: item.diff_p,
          })
      } else {
        await db.submissions_products.put({
          ref_submission: data.id,
          product_type: item.product,
          quantity: item.quantity,
          quantity_driver: item.quantity_driver,
          sum: item.sum,
          boxes_taken: item.boxes_taken,
          diff_kg: item.diff_kg,
          diff_p: item.diff_p,
        })
      }
    }
  }).catch((err) => {
    console.error('Failed to change submission to Dexie.js ' + err.stack)
  })
}

export const getPointsLocal = async (id) => {
  try {
    if (id) {
      const parsedId = parseInt(id)
      const points = await db.points
        .where('tid')
        .equals(parsedId)
        .limit(1)
        .toArray()
      return points
    }
    const all = await db.points.toArray()
    return all
  } catch (e) {
    return e
  }
}

export const getPointDetails = async (id) => {
  if (id) await getPointsLocal(id)
  return
}

export const getProductsLocal = async () => {
  try {
    const all = await db.products.toArray()
    return all
  } catch (e) {
    return e
  }
}

export const getSubmittedProductsBySubmission = async (subId) => {
  const products = await db.submissions_products
    .where('ref_submission')
    .equalsIgnoreCase(subId)
    .toArray()
  return products
}

export const getSubmittedProductsSum = async (subId) => {
  const products = await db.submissions_products
    .where('ref_submission')
    .equalsIgnoreCase(subId)
    .toArray()
  const reducer = (previousValue, currentValue) =>
    parseFloat(previousValue) + parseFloat(currentValue)
  if (products && products.length > 0) {
    const toBePaidSum = products.map((item) => item.sum).reduce(reducer)
    return parseFloat(toBePaidSum)
  }
  return '-'
}

export const getSubmittedProducts = async (subId, type) => {
  try {
    // all products
    if (!subId) {
      const all = await db.products.toArray()
      return all
      // specific product depending on submission key
    } else {
      if (!type) {
        //has only subId
        const p = await db.submissions_products
          .where('ref_submission')
          .equalsIgnoreCase(subId)
          .toArray()
        return p
      } else {
        //has product type and subid

        const p = await db.submissions_products
          .where('ref_submission')
          .equalsIgnoreCase(subId)
          // .where('product_type').equalsIgnoreCase(type)
          .and((item) => item.product_type == type)
          .toArray()

        return p
      }
    }
  } catch (e) {
    return e
  }
}

export const getCurrentVanWeight = async (date) => {
  try {
    // all products

    if (date) {
      let weight = 0
      const submissions = await db.submissions
        .where('date')
        .equalsIgnoreCase(date)
        .toArray()

      for (let sub of submissions) {
        let products = await getSubmittedProducts(sub.id)

        for (let product of products) {
          weight = weight + parseFloat(product.quantity)
        }
      }

      return weight
    }
  } catch (e) {
    return e
  }
}

export const getCurrentProductStats = async (date, type) => {
  try {
    // all products
    if (date && type) {
      let weight = 0
      let sum = 0
      const submissions = await db.submissions
        .where('date')
        .equalsIgnoreCase(date)
        .toArray()

      for (let sub of submissions) {
        let products = await getSubmittedProducts(sub.id, type)
        for (let product of products) {
          weight = weight + parseFloat(product.quantity)
          sum = sum + parseFloat(product.sum)
        }
      }

      return {
        weight: weight,
        sum: sum,
      }
    }
  } catch (e) {
    return e
  }
}

export const addProduct = (productId, productName) => {
  if (!productId || !productName) return
  db.products.put({ tid: productId, product_name: productName })
}

// set products in the Dexie
export const setProductsLocal = (products) => {
  products.forEach((product) => {
    const productId = product.id
    const productName = product.name
    addProduct(parseInt(productId), productName)
  })
}

export const getSubmissions = async (key) => {
  console.log('key', key)
  if (key) {
    const specificSubmission = await db.submissions
      .where('id')
      .equalsIgnoreCase(key)
      .toArray()
    return specificSubmission
  }
  const all = await db.submissions.toArray()
  return all
}

// export const getUniquePointSubmissions = async () => {
//   // console.log('key', key)
//   // if (key) {
//   // const specificSubmissions = await db.submissions
//   //   .where('id')
//   //   .equalsIgnoreCase(key)
//   //   .toArray()
//   // return specificSubmissions
//   // }
//   // const all = await db.submissions.toArray()
//   // return all

//   // return await db.submissions
//   //   .orderBy('purchase_point')
//   //   .uniqueKeys(function (purchasePoint) {
//   //     // This callback will be called once for each unique firstName in DB no matter
//   //     // if having multiple friends with same name.
//   //     console.log('ptt', purchasePoint)
//   //   })

//   // const points = await getPointsLocal()

//   let temp = []
//   for (const point of points) {
//     const sub = await await db.submissions
//       .where({ purchase_point: point.tid })
//       .limit(1)
//       .toArray()
//     if (sub && sub.length > 0) temp.push(sub[0])
//   }

//   return temp
// }

export const getUnsubmittedSubmissions = async () => {
  const unsubmitted = await db.submissions
    .where('status')
    .equalsIgnoreCase('unsubmitted')
    .toArray()
  return unsubmitted
}

export const getSubmittedSubmissions = async () => {
  const unsubmitted = await db.submissions
    .where('status')
    .equalsIgnoreCase('submitted')
    .toArray()
  return unsubmitted
}

export const getSubmissionByDateAndPoint = async (date, point) => {
  const specificSubmission = await db.submissions
    // .where('id').equalsIgnoreCase(key)
    .where({ date: date, purchase_point: point })
    .toArray()

  // if (specificSubmission === undefined || specificSubmission.length === 0) {
  // 	return false
  // }

  return specificSubmission
}

export const totalSubmissionCount = () => {
  const count = db.submissions.count()
  return count
}

export const totalCountForDay = async (pointId) => {
  const now = DateTime.now()
  const date = now.toISODate()
  pointId = parseInt(pointId)
  const count = await db.submissions
    .where({ date: date, purchase_point: pointId })
    .count()
  return count
}

export const getSidebarCollapse = async () => {
  const status = await db.settings
    .where('name')
    .equalsIgnoreCase('sidebar_collapse')
    .toArray()
  return status
}

export const setSidebarCollapse = async (status) => {
  // if (!status) return
  const current = await getSidebarCollapse()
  if (!current || current.length === 0) {
    await db.settings.put({ name: 'sidebar_collapse', contents: status })
  } else {
    await db.settings
      .where('name')
      .equalsIgnoreCase('sidebar_collapse')
      .modify({ contents: status })
  }
}
