import {
  createRxDatabase,
  addRxPlugin,
  addPouchPlugin,
  getRxStoragePouch,
} from 'rxdb'
import { heroSchema } from './Schema'
import { submissionsSchema } from './SubmissionsSchema'
import { pointsSchema } from './PointsSchema'
import { productsSchema } from './ProductsSchema'
import { weightingSchema } from './WeightingSchema'
import { transfersSchema } from './TransfersSchema'
import { transportSchema } from './TransportSchema'
import { fuelSchema } from './FuelSchema'
import { RxDBLeaderElectionPlugin } from 'rxdb/plugins/leader-election'
import { RxDBReplicationCouchDBPlugin } from 'rxdb/plugins/replication-couchdb'
import { replicateRxCollection } from 'rxdb/plugins/replication'
import { getRxStorageDexie } from 'rxdb/plugins/dexie';
import axios from 'axios'

addPouchPlugin(require('pouchdb-adapter-idb'))
addPouchPlugin(require('pouchdb-adapter-http')) // enable syncing over http
addRxPlugin(RxDBLeaderElectionPlugin)
addRxPlugin(RxDBReplicationCouchDBPlugin)

// const syncURL = 'http://' + window.location.hostname + ':10102/'
// const syncURL = 'http://fdsfgghfdgdfg.free.beeceptor.com/'
// console.log('host: ' + syncURL)

let dbPromise = null

export const initialize = async () => {
  console.log('DatabaseService: creating database..')

  const db = await createRxDatabase({
    name: 'berry',
    storage: getRxStorageDexie(),
    // multiInstance: true,
  })

  console.log('DatabaseService: created database')
  window['db'] = db // write to window for debugging

  // create collections
  console.log('DatabaseService: create collections')
  await db.addCollections({
    points: {
      schema: pointsSchema,
      migrationStrategies: {},
      autoMigrate: true,
    },
    products: {
      schema: productsSchema,
      migrationStrategies: {},
      autoMigrate: true,
    },
    weighting: {
      schema: weightingSchema,
      migrationStrategies: {
        1: function (oldDoc) {
          // oldDoc.quantityPoint = oldDoc.quantityDriver
          oldDoc.products.forEach((product) => {
            product.quantityPoint = product.quantityDriver
            product.quantityDriver = null
          })
          return oldDoc
        },
      },
      autoMigrate: true,
    },
    transfers: {
      schema: transfersSchema,
      autoMigrate: true,
      migrationStrategies: {},
    },
    transport: {
      schema: transportSchema,
      autoMigrate: true,
      migrationStrategies: {},
    },
    fuel: {
      schema: fuelSchema,
      autoMigrate: true,
      migrationStrategies: {
        1: function (oldDoc) {
          oldDoc.nid = 0
          return oldDoc
        },
      },
    },
    submissions: {
      schema: submissionsSchema,
      migrationStrategies: {
        1: function (oldDoc) {
          return null
        },
      },
      autoMigrate: true,
      methods: {
        totalQuantity: function () {
          function add(accumulator, a) {
            return accumulator + a
          }
          const sum = this?.products
            ?.map((item) => item.quantity)
            .reduce(add, 0)
          return sum
        },
        sumToBePaid: function () {
          const sum = this?.products?.reduce((sum, item) => sum + item.sum, 0)
          return sum
        },
      },
    },
  })

  const cont = true

  if (cont) {
    if (!db || !db.submissions) {
      return
    }

    const jwt = localStorage.getItem('jwt_berry')
    let user = localStorage.getItem('user')
    let userDetails = JSON.parse(user)

    const ProductsReplication = await replicateRxCollection({
      collection: db.products,
      replicationIdentifier: 'products-replication',
      /**
       * By default it will do a one-time replication.
       * By settings live: true the replication will continuously
       * replicate all changes.
       * (optional), default is false.
       */
      live: true,
      /**
       * Interval in milliseconds on when to run the next replication cycle.
       * Set this to 0 when you have a back-channel from your remote
       * that that tells the client when to fetch remote changes.
       * (optional), only needed when live=true, default is 10 seconds.
       */
      liveInterval: 15000,
      /**
       * Time in milliseconds after when a failed replication cycle
       * has to be retried.
       * (optional), default is 5 seconds.
       */
      retryTime: 15000,
      /**
       * Optional,
       * only needed when you want to replicate remote changes to the local state.
       */
      pull: {
        /**
         * Pull handler
         */
        async handler(latestPullDocument) {
          const limitPerPull = 10
          let lastNid = null
          console.log('latestPullDocument', latestPullDocument)
          const minTimestamp = latestPullDocument
            ? latestPullDocument.updatedAt
            : 0
          if (latestPullDocument?.nid) {
            lastNid = latestPullDocument.nid
          }

          const formattedLastNidString = () => {
            if (lastNid) return `&lastNid=${lastNid}`
            return ''
          }

          console.log('minTimestamp', minTimestamp)
          const syncUrl = `${
            process.env.REACT_APP_BACKEND_URL
          }sync/products?_format=json&minUpdatedAt=${minTimestamp}${formattedLastNidString()}&limit=${limitPerPull}&XDEBUG_TRIGGER`
          const documentsFromRemote = await axios.get(syncUrl, {
            headers: { Authorization: `Bearer ${jwt}` },
          })

          return {
            /**
             * Contains the pulled documents from the remote.
             */
            documents: documentsFromRemote.data.docs,
            /**
             * Must be true if there might be more newer changes on the remote.
             */
            hasMoreDocuments:
              documentsFromRemote.data.docs.length === limitPerPull,
          }
        },
      },
    })

    const PointsReplication = await replicateRxCollection({
      collection: db.points,
      replicationIdentifier: 'points-replication',
      /**
       * By default it will do a one-time replication.
       * By settings live: true the replication will continuously
       * replicate all changes.
       * (optional), default is false.
       */
      live: true,
      /**
       * Interval in milliseconds on when to run the next replication cycle.
       * Set this to 0 when you have a back-channel from your remote
       * that that tells the client when to fetch remote changes.
       * (optional), only needed when live=true, default is 10 seconds.
       */
      liveInterval: 15000,
      /**
       * Time in milliseconds after when a failed replication cycle
       * has to be retried.
       * (optional), default is 5 seconds.
       */
      retryTime: 15000,
      /**
       * Optional,
       * only needed when you want to replicate remote changes to the local state.
       */
      pull: {
        /**
         * Pull handler
         */
        async handler(latestPullDocument) {
          const limitPerPull = 5
          let lastNid = null
          console.log('latestPullDocument', latestPullDocument)
          const minTimestamp = latestPullDocument
            ? latestPullDocument.updatedAt
            : 0
          if (latestPullDocument?.nid) {
            lastNid = latestPullDocument.nid
          }

          const formattedLastNidString = () => {
            if (lastNid) return `&lastNid=${lastNid}`
            return ''
          }

          console.log('minTimestamp', minTimestamp)
          const syncUrl = `${
            process.env.REACT_APP_BACKEND_URL
          }sync/points?_format=json&minUpdatedAt=${minTimestamp}${formattedLastNidString()}&limit=${limitPerPull}&XDEBUG_TRIGGER`
          const documentsFromRemote = await axios.get(syncUrl, {
            headers: { Authorization: `Bearer ${jwt}` },
          })

          return {
            /**
             * Contains the pulled documents from the remote.
             */
            documents: documentsFromRemote.data.docs,
            /**
             * Must be true if there might be more newer changes on the remote.
             */
            hasMoreDocuments:
              documentsFromRemote.data.docs.length === limitPerPull,
          }
        },
      },
    })

    const TransportReplication = await replicateRxCollection({
      collection: db.transport,
      replicationIdentifier: 'transport-replication',
      /**
       * By default it will do a one-time replication.
       * By settings live: true the replication will continuously
       * replicate all changes.
       * (optional), default is false.
       */
      live: true,
      /**
       * Interval in milliseconds on when to run the next replication cycle.
       * Set this to 0 when you have a back-channel from your remote
       * that that tells the client when to fetch remote changes.
       * (optional), only needed when live=true, default is 10 seconds.
       */
      liveInterval: 15000,
      /**
       * Time in milliseconds after when a failed replication cycle
       * has to be retried.
       * (optional), default is 5 seconds.
       */
      retryTime: 15000,
      /**
       * Optional,
       * only needed when you want to replicate remote changes to the local state.
       */
      pull: {
        /**
         * Pull handler
         */
        async handler(latestPullDocument) {
          const limitPerPull = 20
          let lastNid = null
          console.log('latestPullDocument', latestPullDocument)
          const minTimestamp = latestPullDocument
            ? latestPullDocument.updatedAt
            : 0
          if (latestPullDocument?.nid) {
            lastNid = latestPullDocument.nid
          }

          const formattedLastNidString = () => {
            if (lastNid) return `&lastNid=${lastNid}`
            return ''
          }

          console.log('minTimestamp', minTimestamp)
          const syncUrl = `${
            process.env.REACT_APP_BACKEND_URL
          }sync/transport?_format=json&minUpdatedAt=${minTimestamp}${formattedLastNidString()}&limit=${limitPerPull}&XDEBUG_TRIGGER`
          const documentsFromRemote = await axios.get(syncUrl, {
            headers: { Authorization: `Bearer ${jwt}` },
          })

          return {
            /**
             * Contains the pulled documents from the remote.
             */
            documents: documentsFromRemote.data.docs,
            /**
             * Must be true if there might be more newer changes on the remote.
             */
            hasMoreDocuments:
              documentsFromRemote.data.docs.length === limitPerPull,
          }
        },
      },
    })

    const FuelReplication = await replicateRxCollection({
      collection: db.fuel,
      replicationIdentifier: 'fuel-replication',
      /**
       * By default it will do a one-time replication.
       * By settings live: true the replication will continuously
       * replicate all changes.
       * (optional), default is false.
       */
      live: true,
      /**
       * Interval in milliseconds on when to run the next replication cycle.
       * Set this to 0 when you have a back-channel from your remote
       * that that tells the client when to fetch remote changes.
       * (optional), only needed when live=true, default is 10 seconds.
       */
      liveInterval: 15000,
      /**
       * Time in milliseconds after when a failed replication cycle
       * has to be retried.
       * (optional), default is 5 seconds.
       */
      retryTime: 15000,
      /**
       * Optional,
       * only needed when you want to replicate remote changes to the local state.
       */
      pull: {
        /**
         * Pull handler
         */
        async handler(latestPullDocument) {
          const limitPerPull = 20
          let lastNid = null
          console.log('latestPullDocument', latestPullDocument)
          const minTimestamp = latestPullDocument
            ? latestPullDocument.updatedAt
            : 0
          if (latestPullDocument?.nid) {
            lastNid = latestPullDocument.nid
          }

          const formattedLastNidString = () => {
            if (lastNid) return `&lastNid=${lastNid}`
            return ''
          }

          console.log('minTimestamp', minTimestamp)
          const syncUrl = `${
            process.env.REACT_APP_BACKEND_URL
          }sync/fuel?_format=json&minUpdatedAt=${minTimestamp}${formattedLastNidString()}&limit=${limitPerPull}&XDEBUG_TRIGGER`
          const documentsFromRemote = await axios.get(syncUrl, {
            headers: { Authorization: `Bearer ${jwt}` },
          })

          return {
            /**
             * Contains the pulled documents from the remote.
             */
            documents: documentsFromRemote.data.docs,
            /**
             * Must be true if there might be more newer changes on the remote.
             */
            hasMoreDocuments:
              documentsFromRemote.data.docs.length === limitPerPull,
          }
        },
      },
      push: {
        /**
         * Push handler
         */
        async handler(docs) {
          /**
           * Push the local documents to a remote REST server.
           */
          const rawResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}sync/push/fuel?_format=json&XDEBUG_TRIGGER`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({ docs }),
            },
          )
          const content = await rawResponse.json()
        },
        /**
         * Batch size, optional
         * Defines how many documents will be given to the push handler at once.
         */
        batchSize: 5,
      },
    })

    const weightingReplication = await replicateRxCollection({
      collection: db.weighting,
      replicationIdentifier: 'weighting-replication',
      /**
       * By default it will do a one-time replication.
       * By settings live: true the replication will continuously
       * replicate all changes.
       * (optional), default is false.
       */
      live: true,
      /**
       * Interval in milliseconds on when to run the next replication cycle.
       * Set this to 0 when you have a back-channel from your remote
       * that that tells the client when to fetch remote changes.
       * (optional), only needed when live=true, default is 10 seconds.
       */
      liveInterval: 15000,
      /**
       * Time in milliseconds after when a failed replication cycle
       * has to be retried.
       * (optional), default is 5 seconds.
       */
      retryTime: 15000,
      /**
       * Optional,
       * only needed when you want to replicate remote changes to the local state.
       */
      pull: {
        /**
         * Pull handler
         */
        async handler(latestPullDocument) {
          const limitPerPull = 50
          let lastNid = null
          console.log('latestPullDocument', latestPullDocument)
          const minTimestamp = latestPullDocument
            ? latestPullDocument.updatedAt
            : 0
          if (latestPullDocument?.nid) {
            lastNid = latestPullDocument.nid
          }

          const formattedLastNidString = () => {
            if (lastNid) return `&lastNid=${lastNid}`
            return ''
          }

          console.log('minTimestamp', minTimestamp)
          const syncUrl = `${
            process.env.REACT_APP_BACKEND_URL
          }sync/weighting?_format=json&minUpdatedAt=${minTimestamp}${formattedLastNidString()}&limit=${limitPerPull}&XDEBUG_TRIGGER`
          const documentsFromRemote = await axios.get(syncUrl, {
            headers: { Authorization: `Bearer ${jwt}` },
          })

          return {
            /**
             * Contains the pulled documents from the remote.
             */
            documents: documentsFromRemote.data.docs,
            /**
             * Must be true if there might be more newer changes on the remote.
             */
            // hasMoreDocuments:
            //   documentsFromRemote.data.docs.length !== limitPerPull,
            hasMoreDocuments:
              documentsFromRemote.data.docs.length === limitPerPull,
            // hasMoreDocuments: false,
          }
        },
      },
    })

    const transfersReplication = await replicateRxCollection({
      collection: db.transfers,
      replicationIdentifier: 'transfers-replication',
      /**
       * By default it will do a one-time replication.
       * By settings live: true the replication will continuously
       * replicate all changes.
       * (optional), default is false.
       */
      live: true,
      /**
       * Interval in milliseconds on when to run the next replication cycle.
       * Set this to 0 when you have a back-channel from your remote
       * that that tells the client when to fetch remote changes.
       * (optional), only needed when live=true, default is 10 seconds.
       */
      liveInterval: 15000,
      /**
       * Time in milliseconds after when a failed replication cycle
       * has to be retried.
       * (optional), default is 5 seconds.
       */
      retryTime: 15000,
      /**
       * Optional,
       * only needed when you want to replicate remote changes to the local state.
       */
      pull: {
        /**
         * Pull handler
         */
        async handler(latestPullDocument) {
          const limitPerPull = 20
          let lastNid = null
          console.log('latestPullDocument', latestPullDocument)
          const minTimestamp = latestPullDocument
            ? latestPullDocument.updatedAt
            : 0
          if (latestPullDocument?.nid) {
            lastNid = latestPullDocument.nid
          }

          const formattedLastNidString = () => {
            if (lastNid) return `&lastNid=${lastNid}`
            return ''
          }

          console.log('minTimestamp', minTimestamp)
          const syncUrl = `${
            process.env.REACT_APP_BACKEND_URL
          }sync/transfers?_format=json&minUpdatedAt=${minTimestamp}${formattedLastNidString()}&limit=${limitPerPull}&XDEBUG_TRIGGER`
          console.log('syncUrl', syncUrl)
          const documentsFromRemote = await axios.get(syncUrl, {
            headers: { Authorization: `Bearer ${jwt}` },
          })

          return {
            /**
             * Contains the pulled documents from the remote.
             */
            documents: documentsFromRemote.data.docs,
            /**
             * Must be true if there might be more newer changes on the remote.
             */
            // hasMoreDocuments:
            //   documentsFromRemote.data.docs.length !== limitPerPull,
            hasMoreDocuments:
              documentsFromRemote.data.docs.length === limitPerPull,
            // hasMoreDocuments: false,
          }
        },
      },
    })

    const submissionsReplication = await replicateRxCollection({
      collection: db.submissions,
      replicationIdentifier: 'my-custom-rest-replication',
      /**
       * By default it will do a one-time replication.
       * By settings live: true the replication will continuously
       * replicate all changes.
       * (optional), default is false.
       */
      live: true,
      /**
       * Interval in milliseconds on when to run the next replication cycle.
       * Set this to 0 when you have a back-channel from your remote
       * that that tells the client when to fetch remote changes.
       * (optional), only needed when live=true, default is 10 seconds.
       */
      liveInterval: 3000,
      /**
       * Time in milliseconds after when a failed replication cycle
       * has to be retried.
       * (optional), default is 5 seconds.
       */
      retryTime: 3000,
      /**
       * Optional,
       * only needed when you want to replicate remote changes to the local state.
       */
      pull: {
        /**
         * Pull handler
         */
        async handler(latestPullDocument) {
          const limitPerPull = 200
          let lastNid = null
          console.log('latestPullDocument', latestPullDocument)
          const minTimestamp = latestPullDocument
            ? latestPullDocument.updatedAt
            : 0
          if (latestPullDocument?.nid) {
            lastNid = latestPullDocument.nid
          }

          const formattedLastNidString = () => {
            if (lastNid) return `&lastNid=${lastNid}`
            return ''
          }

          const syncUrl = `${
            process.env.REACT_APP_BACKEND_URL
          }sync/submissions?_format=json&minUpdatedAt=${minTimestamp}${formattedLastNidString()}&limit=${limitPerPull}&XDEBUG_TRIGGER`
          console.log('syncUrl', syncUrl)
          const documentsFromRemote = await axios.get(syncUrl, {
            headers: { Authorization: `Bearer ${jwt}` },
          })

          return {
            /**
             * Contains the pulled documents from the remote.
             */
            documents: documentsFromRemote.data.docs,
            /**
             * Must be true if there might be more newer changes on the remote.
             */
            hasMoreDocuments:
              documentsFromRemote.data.docs.length === limitPerPull,
            // hasMoreDocuments: true,
          }
        },
      },
      /**
       * Optional,
       * only needed when you want to replicate local changes to the remote instance.
       */
      push: {
        /**
         * Push handler
         */
        async handler(docs) {
          /**
           * Push the local documents to a remote REST server.
           */
          const rawResponse = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}sync/push/submissions?_format=json&XDEBUG_TRIGGER`,
            {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`,
              },
              body: JSON.stringify({ docs }),
            },
          )
          const content = await rawResponse.json()
        },
        /**
         * Batch size, optional
         * Defines how many documents will be given to the push handler at once.
         */
        batchSize: 5,
      },
    })

    // // await replication.awaitInitialReplication()

    // // emits each document that was recieved from the remote
    // submissionsReplication.received$.subscribe((doc) =>
    //   console.log('subs-received', doc),
    // )

    // PointsReplication.received$.subscribe((doc) =>
    //   console.log('points-received', doc),
    // )

    // // // emits each document that was send to the remote
    // // replication.send$.subscribe((doc) => console.dir('send!!!', doc))

    // // // emits all errors that happen when running the push- & pull-handlers.
    // submissionsReplication.error$.subscribe((errorIncoming) =>
    //   console.log('subs-error', errorIncoming),
    // )

    // TransportReplication.error$.subscribe((errorIncoming) =>
    //   console.log('error!!!-transport', errorIncoming),
    // )

    // FuelReplication.error$.subscribe((errorIncoming) =>
    //   console.log('error!!!-fuel', errorIncoming),
    // )

    // // emits true when the replication was canceled, false when not.
    // submissionsReplication.canceled$.subscribe((bool) =>
    //   console.dir('subs-cancelled', bool),
    // )

    // // emits true when a replication cycle is running, false when not.
    // replication.active$.subscribe((bool) => console.dir('active!!!', bool))
  }

  return db
}

export default initialize
