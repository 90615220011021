import React, { useEffect, useContext } from 'react'
import { Avatar } from 'antd'
import axios from 'axios'
import { AuthContext } from '../../utils/auth-context'

export const UserAvatar = () => {
  const { avatar_url, setAvatarUrl, loggedIn, setLoggedIn, setUsername } =
    useContext(AuthContext)
  const jwt = localStorage.getItem('jwt_berry')
  let user = localStorage.getItem('user')
  let userDetails = JSON.parse(user)

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}user/${userDetails.uid}?_format=json`,
        { headers: { Authorization: `Bearer ${jwt}` } },
      )
      .then((result) => {
        setAvatarUrl(result.data.user_picture[0]['url'])
        setLoggedIn(true)
        result?.data?.name[0]?.value && setUsername(result.data.name[0].value)
      })
      .catch((error) => {})
  }, [])

  return (
    <>
      {/* <Avatar src={avatar_url} /> */}
      <strong>
        <span style={{ color: 'white', padding: '0 5px' }}>
          {userDetails.name}
        </span>
      </strong>
    </>
  )
}
